/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Grid, TextField, Switch } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import styles from '../../../styles/Layout.module.css';
import api from '../../../service/axios/api';

const customStyles = {
  control: provided => ({
    ...provided,
    // width: 200,
    backgroundColor: 'none',
    // boxShadow: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    color: '#717171',
    // color: state.data.color,
    // backgroundColor: state.data.bgcolor,
    // fontSize: 24,
  }),
  placeholder: () => ({
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    marginTop: -20,
    color: '#717171',
    // paddingBottom: 20,
    // fontSize: 24,
    // marginBottom: 30,
  }),
};

const useStyles = makeStyles(() => ({
  myTextField: {
    backgroundColor: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  text: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: '#717171',
  },
  mySwitch: {
    '&.MuiSwitch-colorPrimary.MuiSwitch-checked': {
      color: 'green',
    },
  },
  checked: {},
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#41b490',
    },
    '&$checked + $track': {
      backgroundColor: '#41b490',
    },
  },
  track: {},
}));

// eslint-disable-next-line prettier/prettier
export function Service({
  valueCredit,
  setValueCredit,
  nf,
  setNf,
  boleto,
  setBoleto,
  paymentDate,
  setPaymentDate,
  isNew,
  orderName,
  setOrderName,
  nfComments,
  setNfComments,
  setValueService,
  valueService,
  service,
  setService,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [serviceOption, setServiceOption] = useState([]);

  const handleChangeBoleto = e => {
    if (e) {
      setBoleto(e);
      setNf(e);
    } else {
      setBoleto(e);
    }
  };

  useEffect(() => {
    const cancel = new AbortController();
    api.order
      .getNfServices(cancel)
      .then(res => {
        const list = [];
        res.retorno.forEach(item => {
          list.push({
            label: `${item.service_code} - ${item.description}`,
            value: item.id,
          });
        });
        setServiceOption(list);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h6 className={styles.textBoxInform}>Insira os dados da nota</h6>
        {isNew && (
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            {/* <Grid item>
              {t('newOrder.salesCredit.ticket')}
              <Switch
                checked={boleto}
                onChange={e => handleChangeBoleto(e.target.checked)}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.checked,
                  track: classes.track,
                }}
              />
            </Grid>
            <Grid item>
              <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                <DatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  // style={{ backgroundColor: '#fff', borderRadius: '30px' }}
                  label={t('newOrder.salesCredit.expirationDate')}
                  size="small"
                  format="dd/MM/yyyy"
                  value={paymentDate}
                  InputProps={{
                    readOnly: true,
                    style: {
                      borderRadius: '25px',
                      textAlign: 'center',
                      alignItems: 'center',
                      justifyContent: 'center',
                      maxWidth: '155px',
                    },
                  }}
                  onChange={date => setPaymentDate(date)}
                />
              </MuiPickersUtilsProvider>
            </Grid> */}
          </div>
        )}
      </div>

      {isNew && (
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Grid item xs={12} style={{ marginTop: '15px' }}>
            <TextField
              className={classes.text}
              variant="outlined"
              size="small"
              fullWidth
              placeholder={t('newOrder.salesCredit.orderName')}
              value={orderName}
              onChange={e => setOrderName(e.target.value)}
              InputProps={{
                classes: {
                  notchedOutline: classes.myTextField,
                  input: classes.text,
                },
              }}
            />
          </Grid>
        </Grid>
      )}

      <Grid
        container
        spacing={1}
        direction="row"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: 10,
          marginTop: 50,
          marginBottom: 50,
        }}
      >
        <Grid item xs={6}>
          <p className={styles.labelTitleResum}>Serviço</p>
          <Select
            // width="300"
            styles={customStyles}
            value={service}
            classNamePrefix="select"
            placeholder="Serviço"
            onChange={setService}
            // isSearchable={false}
            options={serviceOption}
          />
        </Grid>
        <Grid item xs={6}>
          <p className={styles.labelTitleResum}>Valor da nota</p>
          <TextField
            fullWidth
            type="number"
            className={classes.text}
            variant="outlined"
            size="small"
            value={valueService && valueService}
            onChange={e => setValueService(e.target.value)}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Grid>
        <Grid item xs={10} style={{ marginTop: '15px' }}>
          <TextField
            className={classes.text}
            variant="outlined"
            size="small"
            fullWidth
            placeholder={t('newOrder.salesCredit.comments')}
            value={nfComments}
            onChange={e => setNfComments(e.target.value)}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
            multiline
            // rowsMax={4}
            rows={4}
          />
        </Grid>
      </Grid>
    </div>
  );
}
