/* eslint-disable jsx-a11y/aria-role */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
// import { makeStyles } from '@mui/styles';
import { Grid, Avatar, MenuItem, Menu } from '@material-ui/core';
import routing from '../../service/routes/routing';
// eslint-disable-next-line no-unused-vars
import api from '../../service/axios/api';
// import PermissionComponent from '../../components/permissionComponent/PermissionComponent';
import './dashBoard.css';
// import UserRoles from '../../service/acl/UserRoles';

// eslint-disable-next-line no-unused-vars
const myuseStyles = makeStyles(theme => ({
  myButton: {
    color: '#2a3176',
    backgroundColor: '#f4f4f4',
    fontWeight: 'bold',
    // minWidth: 200,
  },
  rootGrid: {
    display: 'flex',
    justifyContent: 'center',
    padding: 40,
  },
  myGridG: {
    margin: 10,
    minWidth: 660,
    maxWidth: 320,
    minHeight: 170,
    // marginBottom: -30,
    backgroundColor: '#f4f4f4',
    // margin: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    cursor: 'pointer',
    border: '2px solid #c5c5c5',
    borderRadius: 10,
    // direction: 'column',
  },
  myGridSG: {
    minWidth: 320,
    maxWidth: 640,
    minHeight: 170,
    backgroundColor: '#2A3176',
    margin: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    display: 'block',
    textAlign: 'center',
    cursor: 'pointer',
    border: '2px solid #c5c5c5',
    borderRadius: 10,
    // direction: 'column',
  },
  myGrid: {
    margin: 10,
    minWidth: 320,
    maxWidth: 320,
    minHeight: 170,
    // marginBottom: -30,
    backgroundColor: '#f4f4f4',
    // margin: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    display: 'block',
    textAlign: 'center',
    cursor: 'pointer',
    border: '2px solid #c5c5c5',
    borderRadius: 10,
    // direction: 'column',
  },
  myGridS: {
    minWidth: 320,
    maxWidth: 320,
    minHeight: 170,
    backgroundColor: '#2A3176',
    margin: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    display: 'block',
    textAlign: 'center',
    cursor: 'pointer',
    border: '2px solid #c5c5c5',
    borderRadius: 10,
    // direction: 'column',
  },
  myLabel: {
    padding: theme.spacing(1),
    fontFamily: 'Open Sans',
    color: '#707070',
    fontWeight: 'bold',
  },
  myLabelS: {
    padding: theme.spacing(1),
    fontFamily: 'Open Sans',
    color: '#fff',
    fontWeight: 'bold',
  },
}));

export default function dashBoard() {
  const classes = myuseStyles();
  const { t } = useTranslation();
  const [language, setLanguage] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const history = useHistory();
  const [selected, setSelected] = useState();

  // const userLocale = navigator?.languages?.length
  //   ? navigator.languages[0]
  //   : navigator.language;

  // console.log('1', userLocale.substring(0, 2)); // 👉️ "pr-BR", essa opção vai retornar o idioma preferencial

  // Ou da seguinte maneira 👇️
  // console.log('2', navigator.languages); //

  useEffect(() => {
    api.language
      .get()
      .then(l => {
        if (l) {
          setLanguage(l);
        } else {
          setLanguage('pt');
        }
      })
      .catch(() => {
        setLanguage('pt');
      });
  }, []);

  // const handleMenu = event => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguage = lng => {
    api.language.set(lng);
    // i18n.changeLanguage(lng);
    window.location.reload();
    handleClose();
  };

  const click = e => {
    if (e === 'painel') {
      if (api.currentUser.user.role.id === '3') {
        setSelected(e);
        history.push(routing[`${e}c`]);
      } else if (
        api.currentUser.id === '8' ||
        api.currentUser.id === '9' ||
        api.currentUser.id === '278' ||
        api.currentUser.id === '214' ||
        api.currentUser.id === '215' ||
        api.currentUser.id === '255'
      ) {
        setSelected(e);
        history.push(routing[e]);
      }
    } else {
      setSelected(e);
      history.push(routing[e]);
    }
  };

  return (
    <>
      <Grid
        className="baseAvatar"
        style={{
          marginTop: 5,
          display: 'flex',
          justifyContent: 'end',
          backgroundColor: 'transparent',
        }}
      >
        <div style={{ marginTop: 40 }} />
        {/* {language === 'en' && (
          <Avatar
            alt="Remy Sharp"
            src="/img/eua.png"
            style={{ marginTop: 20, marginLeft: -60 }}
            onClick={handleMenu}
          />
        )}
        {language === 'es' && (
          <Avatar
            alt="Remy Sharp"
            src="/img/espanha.png"
            style={{ marginTop: 20, marginLeft: -60 }}
            onClick={handleMenu}
          />
        )}
        {language === 'pt' && (
          <Avatar
            alt="Remy Sharp"
            src="/img/brasil.png"
            style={{ marginTop: 20 }}
            onClick={handleMenu}
          />
        )}
        {!language && (
          <Avatar
            alt="Remy Sharp"
            src="/img/brasil.png"
            style={{ marginTop: 20, marginLeft: -60 }}
            onClick={handleMenu}
          />
        )} */}
      </Grid>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {language !== 'en' && (
          <MenuItem
            // className={classes.textNotification}
            onClick={() => handleLanguage('en')}
          >
            <Avatar
              alt="English"
              src="./img/eua.png"
              style={{ width: 30, height: 30, marginRight: 15 }}
            />
            English
          </MenuItem>
        )}

        {language !== 'pt' && (
          <MenuItem
            // className={classes.textNotification}
            onClick={() => handleLanguage('pt')}
          >
            <Avatar
              alt="Portugues"
              src="./img/brasil.png"
              style={{ width: 30, height: 30, marginRight: 15 }}
            />
            Português
          </MenuItem>
        )}

        {language !== 'es' && (
          <MenuItem
            // className={classes.textNotification}
            onClick={() => handleLanguage('es')}
          >
            <Avatar
              alt="Spanish"
              src="./img/espanha.png"
              style={{ width: 30, height: 30, marginRight: 15 }}
            />
            Spanish
          </MenuItem>
        )}
      </Menu>
      <div style={{ marginTop: '1%' }}>
        {console.log(api.currentUser?.user?.role?.id)}
        <Grid container spacing={4} className={classes.rootGrid}>
          <Grid
            className={selected === 'orders' ? classes.myGridS : classes.myGrid}
            // style={{ marginRight: 20 }}
            item
            xs={12}
            sm={8}
            md={2}
            lg={4}
            xl={2}
            onClick={() => click('fidelityextract')}
          >
            {api.currentUser?.user?.role?.id === '11' ? (
              <>
                <img
                  src="/img/extract.svg"
                  alt="logo"
                  style={{ width: '6rem', height: '6rem' }}
                />
                <h4
                  className={
                    selected === 'orders' ? classes.myLabelS : classes.myLabel
                  }
                >
                  {/* {t('DashBoard.orders')} */}
                  Extrato
                </h4>
              </>
            ) : (
              <>
                <img
                  src="/img/fidelity.svg"
                  alt="logo"
                  style={{ width: '6rem', height: '6rem' }}
                />
                <h4
                  className={
                    selected === 'orders' ? classes.myLabelS : classes.myLabel
                  }
                >
                  {/* {t('DashBoard.orders')} */}
                  Extrato Fidelidade
                </h4>
              </>
            )}
          </Grid>
          <Grid
            // style={{ marginRight: 20 }}
            // onClick={() => click('moviment')}
            className={classes.myGrid}
            item
            xs={12}
            sm={8}
            md={2}
            lg={4}
            xl={2}
          >
            <img
              src="/img/formacao1.png"
              color="#fefe"
              alt="logo"
              // className="img-2"
              style={{ width: '6rem', height: '6rem' }}
            />
            <h4 className={classes.myLabel}>{t('tutorial')}</h4>
          </Grid>
        </Grid>
        {/* <Grid
          container
          spacing={4}
          // style={{
          //   justifyContent: 'center', marginTop: -40,
          // }}
          className="rootGrid"
        >
          <Grid
            className={
              selected === 'product' ? classes.myGridS : classes.myGrid
            }
            // style={{ marginRight: 20 }}
            item
            xs={12}
            sm={8}
            md={2}
            lg={4}
            xl={2}
            // onClick={() => click('baseCards')}
          >
            <img
              src="/img/formacao1.png"
              color="#fefe"
              alt="logo"
              // className="img-2"
              style={{ width: '6rem', height: '6rem' }}
            />
            <h4 className={classes.myLabel}>{t('tutorial')}</h4>
          </Grid>
          <Grid
            // onClick={() => click('painel')}
            className={classes.myGrid}
            item
            xs={12}
            sm={8}
            md={2}
            lg={4}
            xl={2}
          >
            <img
              src="/img/dashboard1.svg"
              color="#fefe"
              alt="logo"
              style={{ width: '6rem', height: '6rem' }}
            />
            <h4 className={classes.myLabel}>{t('DashBoard.dashBoard')}</h4>
          </Grid>
        </Grid> */}
        <Grid
          container
          spacing={4}
          // style={{
          //   justifyContent: 'center', marginTop: -40,
          // }}
          className="rootGrid"
        >
          <Grid
            onClick={() => click('fidelitycontract')}
            className={classes.myGridG}
            item
            xs={12}
            sm={8}
            md={2}
            lg={4}
            xl={2}
          >
            <img
              src="/img/Element-B.svg"
              color="#fefe"
              alt="logo"
              style={{ width: '6rem', height: '6rem' }}
            />
            <h4 className={classes.myLabel}>Contrate Element-B</h4>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
