/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import styles from '../../../styles/Layout.module.css';
import './checkout.css';

const useStyles = makeStyles(theme =>
  createStyles({
    myButton: {
      color: '#2a3176',
      backgroundColor: '#f4f4f4',
      fontWeight: 'bold',
    },
    myGrid: {
      minWidth: 260,
      backgroundColor: '#f4f4f4',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #c5c5c5',
      borderRadius: 10,
    },
    myGridSelected: {
      minWidth: 260,
      backgroundColor: '#41b490',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #41b490',
      borderRadius: 10,
    },
    myLabel: {
      padding: theme.spacing(6),
      color: '#2a3176',
      fontWeight: 'bold',
    },
    myLabelSelected: {
      padding: theme.spacing(6),
      color: '#fff',
      fontWeight: 'bold',
    },
  }),
);

export default function SelectProd({ setTypeCard }) {
  const [selected, setSelected] = useState();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    if (selected) {
      setTypeCard(selected);
    }
  }, [selected]);
  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <h6 className={styles.textBoxInform}>
          {t('newOrder.typeProduct.label')}
        </h6>
      </div>
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'center',
          padding: 40,
        }}
      >
        <Grid
          className={
            selected === 'saque' ? classes.myGridSelected : classes.myGrid
          }
          item
          xs={12}
          sm={8}
          md={2}
          lg={2}
          xl={2}
          onClick={() => setSelected('saque')}
        >
          <p
            className={
              selected === 'saque' ? classes.myLabelSelected : classes.myLabel
            }
          >
            {/* {t('newOrder.card')} */}
            Físico
            <br />
            {t('newOrder.typeProduct.withdraw')}
          </p>
        </Grid>
        <Grid
          className={
            selected === 'compra' ? classes.myGridSelected : classes.myGrid
          }
          item
          xs={12}
          sm={8}
          md={2}
          lg={2}
          xl={2}
          onClick={() => setSelected('compra')}
        >
          <p
            className={
              selected === 'compra' ? classes.myLabelSelected : classes.myLabel
            }
          >
            {/* {t('newOrder.card')} */}
            Físico
            <br />
            {t('newOrder.typeProduct.gift')}
          </p>
        </Grid>
        <Grid
          className={
            selected === 'virtualCard' ? classes.myGridSelected : classes.myGrid
          }
          item
          xs={12}
          sm={8}
          md={2}
          lg={2}
          xl={2}
          onClick={() => setSelected('virtualCard')}
        >
          <p
            className={
              selected === 'virtualCard' ? classes.myLabelSelected : classes.myLabel
            }
          >
            {t('newOrder.virtualCard')}
            <br />
            {t('newOrder.typeProduct.gift')}
          </p>
        </Grid>
        {/* <Grid
          className={
            selected === 'presente' ? classes.myGridSelected : classes.myGrid
          }
          item
          xs={12}
          sm={8}
          md={2}
          lg={2}
          xl={2}
          onClick={() => setSelected('presente')}
        >
          <p
            className={
              selected === 'presente'
                ? classes.myLabelSelected
                : classes.myLabel
            }
          >
            Cartão
            <br />
            Presente
          </p>
        </Grid> */}
      </Grid>
      {/* <div style={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 40,
      }}
      >
        <button id="card" type="button"
         onClick={() => setSelected('card')} className={selected === 'card' ?
         'btn-category-selected' : 'btn-category'} style={{ width: 300, height: 150 }}>
          Cartão
          <br />
          Saque/Compra
        </button>
        <button id="transfer" type="button"
        onClick={() => setSelected('compra')} className={selected
           === 'compra' ? 'btn-category-selected' : 'btn-category'}
            style={{ width: 300, height: 150, marginLeft: 15 }}>
          Cartão
          <br />
          Compra
        </button>
        <button id="account" type="button" onClick={() => s
          etSelected('present')} className={selected === 'present'
           ? 'btn-category-selected' : 'btn-category'} s
           tyle={{ width: 300, height: 150, marginLeft: 15 }}>
          Cartão
          <br />
          Presente
        </button>
      </div> */}
      <Dialog
        onClose={() => setOpen(!open)}
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="simple-dialog-title">AVISO IMPORTANTE</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Estamos confirmando a instabilidade no sistema do Banco com
            repercussão no APP Joycard. Se persistir o problema, faremos contato
            para adotar temporariamente liberação através de Transferência
            Bancária dos favorecidos. Tão logo se regularize a aplicação,
            retornaremos o fluxo normal do processamento.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            style={{ height: 40 }}
            onClick={() => setOpen(false)}
            color="primary"
            variant="contained"
          >
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
