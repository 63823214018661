/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
// import './checkout.css';
import { useTranslation } from 'react-i18next';
import { Grid, Tooltip } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import styles from '../../../styles/Layout.module.css';
import api from '../../../service/axios/api';

const useStyles = makeStyles(theme =>
  createStyles({
    myButton: {
      color: '#2a3176',
      backgroundColor: '#f4f4f4',
      fontWeight: 'bold',
    },
    myGrid: {
      minWidth: 260,
      maxHeight: 100,
      backgroundColor: '#f4f4f4',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #c5c5c5',
      borderRadius: 10,
    },
    myGridSelected: {
      minWidth: 260,
      maxHeight: 100,
      backgroundColor: '#41b490',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
      border: '2px solid #41b490',
      borderRadius: 10,
    },
    myGridDisabled: {
      minWidth: 260,
      maxHeight: 100,
      backgroundColor: '#f4f4f4',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'not-allowed',
      // border: '2px solid #41b490',
      borderRadius: 10,
    },
    myLabel: {
      padding: theme.spacing(6),
      color: '#2a3176',
      fontWeight: 'bold',
    },
    myLabelSelected: {
      padding: theme.spacing(6),
      color: '#fff',
      fontWeight: 'bold',
    },
    myLabelDisabled: {
      padding: theme.spacing(6),
      color: '#b7bad6',
      fontWeight: 'bold',
    },
  }),
);

// eslint-disable-next-line react/prop-types
export default function CatProd({ selected, setSelected }) {
  const { t } = useTranslation();
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [type, setType] = useState({ hasCard: false, hasTransfer: false });

  useEffect(() => {
    if (api?.currentUser?.user?.role?.id === '1') {
      setType({ hasCard: true, hasTransfer: true });
    } else {
      const cancel = new AbortController();
      api.contract
        .getContractType(cancel)
        .then(res => setType(res))
        .catch(e => console.log(e));
    }
  }, []);
  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <h6 className={styles.textBoxInform}>{t('newOrder.changeProduct')}</h6>
      </div>
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'center',
          padding: 40,
        }}
      >
        <Tooltip
          title={
            !type.hasCard
              ? 'Você não possui contrato(s) deste tipo de produto'
              : ''
          }
        >
          <Grid
            className={
              type.hasCard
                ? selected === 'pre'
                  ? classes.myGridSelected
                  : classes.myGrid
                : classes.myGridDisabled
            }
            item
            xs={12}
            sm={8}
            md={3}
            lg={3}
            xl={3}
            onClick={() => type.hasCard && setSelected('pre')}
          >
            <p
              className={
                type.hasCard
                  ? selected === 'pre'
                    ? classes.myLabelSelected
                    : classes.myLabel
                  : classes.myLabelDisabled
              }
            >
              {t('newOrder.card')}
              <br />
              {t('newOrder.cardType')}
            </p>
          </Grid>
        </Tooltip>
        <Tooltip
          title={
            !type.hasTransfer
              ? 'Você não possui contrato(s) deste tipo de produto'
              : ''
          }
        >
          <Grid
            className={
              type.hasTransfer
                ? selected === 'transfer'
                  ? classes.myGridSelected
                  : classes.myGrid
                : classes.myGridDisabled
            }
            item
            xs={12}
            sm={8}
            md={3}
            lg={3}
            xl={3}
            onClick={() => type.hasTransfer && setSelected('transfer')}
          >
            <p
              className={
                type.hasTransfer
                  ? selected === 'transfer'
                    ? classes.myLabelSelected
                    : classes.myLabel
                  : classes.myLabelDisabled
              }
            >
              {t('newOrder.transfer')}
            </p>
          </Grid>
        </Tooltip>
        {/* <Tooltip
          title={
            !type.hasCard
              ? 'Você não possui contrato(s) deste tipo de produto'
              : ''
          }
        > */}
        {(api.currentUser.id === '214' ||
          api.currentUser.id === '215' ||
          api.currentUser.id === '8' ||
          api.currentUser.id === '9' ||
          api.currentUser.id === '278' ||
          api.currentUser.id === '18' ||
          api.currentUser.id === '240' ||
          api.currentUser.id === '287' ||
          api.currentUser.id === '255' ||
          api.currentUser.id === '1203') && (
          <Grid
            className={
              selected === 'service' ? classes.myGridSelected : classes.myGrid
            }
            item
            xs={12}
            sm={8}
            md={3}
            lg={3}
            xl={3}
            onClick={() => setSelected('service')}
          >
            <p
              className={
                selected === 'service'
                  ? classes.myLabelSelected
                  : classes.myLabel
              }
            >
              Serviços
            </p>
          </Grid>
        )}
        {/* </Tooltip> */}
        {/* <Grid
          className={
            type.hasTransfer
              ? selected === 'DCP'
                ? classes.myGridSelected
                : classes.myGrid
              : classes.myGridDisabled
          }
          item
          xs={12}
          sm={8}
          md={3}
          lg={3}
          xl={3}
          onClick={() => type.hasTransfer && setSelected('DCP')}
        >
          <p
            className={
              type.hasTransfer
                ? selected === 'DCP'
                  ? classes.myLabelSelected
                  : classes.myLabel
                : classes.myLabelDisabled
            }
          >
            Demonstrativo <br /> Consolidado
          </p>
        </Grid> */}
      </Grid>
    </div>
  );
}
