/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import {
  // Tooltip,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import EditIcon from '@material-ui/icons/Edit';
// import GetAppIcon from '@material-ui/icons/GetApp';

// import { useExcelDownloder } from 'react-xls';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';

import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import _ from 'lodash';
import MyExcelExport from './MyExcelExport';
import Loading from '../../components/loading/Loading';

import FlashContext from '../../components/contexts/FlashContext';

import api from '../../service/axios/api';

const optionsStatus = [
  {
    label: 'Ativo',
    value: 'Active',
  },
  {
    label: 'Aguardando Link',
    value: 'AwaitingIssuerLink',
  },
  {
    label: 'Aguardando finalizar',
    value: 'AwaitingRelease',
  },
  {
    label: 'Aguardando pagamento',
    value: 'AwaitingPayment',
  },
  {
    label: 'Estoque',
    value: 'InStock',
  },
  {
    label: 'Fraudado',
    value: 'Fraud',
  },
  {
    label: 'Cancelado',
    value: 'Canceled',
  },
];

const optionsFlag = [
  {
    label: 'MasterCard',
    value: 'mastercard',
  },
  {
    label: 'Visa',
    value: 'visa',
  },
  {
    label: 'Cabal',
    value: 'cabal',
  },
];

const optionsCardType = [
  {
    label: 'Saque/Compra',
    value: true,
  },
  {
    label: 'Compra',
    value: false,
  },
];

const customStylesEdit = {
  control: provided => ({
    ...provided,
    // width: 105,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

function createData(
  cardId,
  clientId,
  name,
  cpf,
  status,
  statusBankly,
  proxy,
  cardNum,
  flag,
  validate,
  Billed,
  all,
) {
  // const density = population / size;
  return {
    cardId,
    clientId,
    name,
    cpf,
    status,
    statusBankly,
    proxy,
    cardNum,
    flag,
    validate,
    Billed,
    all,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// eslint-disable-next-line no-unused-vars
export default function CardsTable({
  docs,
  setDocs,
  page,
  setPage,
  itens,
  setItens,
  total,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);

  const [dataExcel, setDataExcel] = useState();
  const [dataEdit, setDataEdit] = useState();
  const [typeSelected, setTypeSelected] = useState({
    label: 'tipo',
    value: 'value',
  });
  const [typeCardSelected, setTypeCardSelected] = useState();
  const [cardFlag, setCardFlag] = useState();

  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const { notifyError, notifySuccess } = useContext(FlashContext);
  const [selectedDate, handleDateChange] = useState();

  const name = useRef(null);
  const cpf = useRef(null);
  const proxy = useRef(null);
  const cardNumber = useRef(null);
  const emissor = useRef(null);

  // const { ExcelDownloder, Type } = useExcelDownloder();

  useEffect(() => {
    if (dataEdit) {
      handleDateChange(new Date(`${dataEdit?.validate} 00:01:01`));
      switch (dataEdit.status) {
        case 'Active':
          setTypeSelected({
            label: 'Ativo',
            value: 'Active',
          });
          break;
        case 'AwaitingIssuerLink':
          setTypeSelected({
            label: 'Aguardando Link',
            value: 'AwaitingIssuerLink',
          });
          break;
        case 'AwaitingRelease':
          setTypeSelected({
            label: 'Aguardando finalizar',
            value: 'AwaitingRelease',
          });
          break;
        case 'InStock':
          setTypeSelected({
            label: 'Estoque',
            value: 'InStock',
          });
          break;
        case 'Fraud':
          setTypeSelected({
            label: 'Fraudado',
            value: 'Fraud',
          });
          break;
        case 'Canceled':
          setTypeSelected({
            label: 'Cancelado',
            value: 'Canceled',
          });
          break;
        default:
          console.log('Deu erro');
      }
      switch (dataEdit.flag) {
        case 'mastercard':
          setCardFlag({
            label: 'MasterCard',
            value: 'mastercard',
          });
          break;
        case 'cabal':
          setCardFlag({
            label: 'Cabal',
            value: 'cabal',
          });
          break;
        case 'visa':
          setCardFlag({
            label: 'Visa',
            value: 'visa',
          });
          break;
        default:
          console.log('Deu erro');
      }
      switch (dataEdit?.all?.product) {
        case 'Saque/Compra':
          setTypeCardSelected({
            label: 'Saque/Compra',
            value: true,
          });
          break;
        case 'Compra':
          setTypeCardSelected({
            label: 'Compra',
            value: false,
          });
          break;
        default:
          console.log('Deu erro');
      }
    }
  }, [dataEdit]);

  useEffect(() => {
    const list = [];
    const listCard = [];
    docs.forEach(d => {
      list.push(
        createData(
          d.id,
          d.Individual && d.Individual.id,
          d.Individual && d.Individual.name,
          d.Individual && d.Individual.cpf,
          d.status,
          d.bank_status,
          d.proxy,
          d.card_number,
          d.brand,
          d.good_through,
          d.Billed,
          d,
        ),
      );
      listCard.push({
        CardId: d.id,
        ClientId: d.Individual && d.Individual.id,
        Nome: d.Individual && d.Individual.name,
        CPF: d.Individual && d.Individual.cpf,
        Status: d.status,
        StatusBankly: d.bank_status,
        Proxy: d.proxy,
        Card: d.card_number,
        Bandeira: d.brand,
        Faturado: d.Billed,
      });
    });
    setDataExcel({ Cartoes: listCard });
    setRows(list);
  }, [docs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(event.target.value);
  };

  const edit = data => {
    setDataEdit(data);
    setOpen(true);
  };

  const mountDataExcel = d => {
    return <MyExcelExport allData={d} />;
  };

  const updateCard = e => {
    e.preventDefault();
    setMsg('Atualizando cartão...');
    setLoading(true);
    const orig = _.cloneDeep(docs);
    api.product
      .updateCardAdmin(
        dataEdit.cardId,
        typeSelected.value,
        cardNumber.current.value,
        proxy.current.value,
        cardFlag.value,
        typeCardSelected.value,
        emissor.current.value,
        selectedDate,
      )
      .then(() => {
        if (dataEdit.clientId) {
          setMsg('Atualizando cliente...');
          api.individual
            .update(dataEdit.clientId, name.current.value, cpf.current.value)
            .then(() => {
              const found = orig.indexOf(
                orig.find(el => el.id === dataEdit.cardId),
              );
              const dest = orig.find(el => el.id === dataEdit.cardId);

              dest.Individual.name = name.current.value;
              dest.Individual.cpf = cpf.current.value;
              dest.proxy = proxy.current.value;
              dest.card_number = cardNumber.current.value;
              dest.status = typeSelected.value;
              orig.splice(found, 1, dest);
              setDocs(orig);

              setLoading(false);
              notifySuccess('Atualização realizada com sucesso');
              setOpen(false);
            })
            .catch(err => {
              setLoading(false);
              notifyError(err.message);
            });
        } else {
          setLoading(false);
          notifySuccess('Atualização realizada com sucesso');
          setOpen(false);
        }
      })
      .catch(ee => {
        setLoading(false);
        console.log(ee);
        notifyError(ee.message);
      });
  };

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {/* <StyledTableCell>CardId</StyledTableCell> */}
                {/* <StyledTableCell>ClientId</StyledTableCell> */}
                <StyledTableCell>Nome</StyledTableCell>
                <StyledTableCell>CPF</StyledTableCell>
                <StyledTableCell style={{ width: 100 }}>Status</StyledTableCell>
                <StyledTableCell style={{ width: 120 }}>
                  Status bankly
                </StyledTableCell>
                <StyledTableCell>Proxy</StyledTableCell>
                <StyledTableCell>Número do cartão</StyledTableCell>
                <StyledTableCell>Bandeira</StyledTableCell>
                <StyledTableCell style={{ width: 120 }}>
                  Validade
                </StyledTableCell>
                <StyledTableCell>Faturado</StyledTableCell>
                <StyledTableCell align="right">
                  <div
                    style={{
                      display: 'flex',
                      // alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    Ação
                    {dataExcel && (
                      <>
                        {/* <ExcelDownloder
                          data={dataExcel}
                          style={{ marginLeft: 15, marginRight: -15 }}
                          filename="Cartões"
                          type={Type.Link}
                        >
                          {console.log('Carreguei')}
                          <h6
                            style={{
                              // marginRight: 30,
                              // color: '#41B490',
                              // fontFamily: 'Open Sans',
                              // textDecoration: 'none',
                              cursor: 'pointer',
                            }}
                          >
                            <Tooltip title="Exportar para Excel" arrow>
                              <GetAppIcon />
                            </Tooltip>
                          </h6>
                        </ExcelDownloder> */}
                        {dataExcel && mountDataExcel(dataExcel)}
                        {/* <MyExcelExport allData={dataExcel} /> */}
                      </>
                    )}
                  </div>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <StyledTableRow key={i}>
                  {/* <StyledTableCell component="th" scope="row">
                    {row.cardId}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.clientId}
                  </StyledTableCell> */}
                  <StyledTableCell>{row.name}</StyledTableCell>
                  <StyledTableCell>{row.cpf}</StyledTableCell>
                  <StyledTableCell style={{ width: 100 }}>
                    {row.status}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: 120 }}>
                    {row.statusBankly}
                  </StyledTableCell>
                  <StyledTableCell>{row.proxy}</StyledTableCell>
                  <StyledTableCell>{row.cardNum}</StyledTableCell>
                  <StyledTableCell>{row.flag}</StyledTableCell>
                  <StyledTableCell style={{ width: 120 }}>
                    {row.validate}
                  </StyledTableCell>
                  <StyledTableCell>{row.Billed}</StyledTableCell>
                  <StyledTableCell align="right">
                    <EditIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => edit(row)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
          component="div"
          count={total}
          rowsPerPage={itens}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* MODAL DE EDIÇÃO */}
      <Dialog
        onClose={() => setOpen(!open)}
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loading} msg={msg} />
        <DialogTitle id="simple-dialog-title">Edição de cartão</DialogTitle>
        <form onSubmit={updateCard}>
          <Grid
            container
            spacing={1}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: 20,
            }}
          >
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <h6>CardId</h6>
                  <TextField
                    disabled
                    size="small"
                    variant="outlined"
                    fullWidth
                    defaultValue={dataEdit && dataEdit.cardId}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>ClientId</h6>
                  <TextField
                    disabled
                    size="small"
                    variant="outlined"
                    fullWidth
                    defaultValue={dataEdit && dataEdit.clientId}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <h6>Nome</h6>
                  <TextField
                    inputRef={name}
                    size="small"
                    variant="outlined"
                    fullWidth
                    defaultValue={dataEdit && dataEdit.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>CPF</h6>
                  <TextField
                    inputRef={cpf}
                    size="small"
                    variant="outlined"
                    fullWidth
                    defaultValue={dataEdit && dataEdit.cpf}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <h6>Status</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    classNamePrefix="select"
                    placeholder="Tipo"
                    value={typeSelected}
                    onChange={setTypeSelected}
                    isSearchable={false}
                    options={optionsStatus}
                    menuPortalTarget={document.body}
                  />
                </Grid>
                <Grid item xs={12} sm={6} disabled>
                  <h6>Bandeira</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    value={cardFlag}
                    onChange={setCardFlag}
                    classNamePrefix="select"
                    placeholder="Tipo"
                    isSearchable={false}
                    options={optionsFlag}
                    menuPortalTarget={document.body}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <h6>Proxy</h6>
                  <TextField
                    inputRef={proxy}
                    size="small"
                    variant="outlined"
                    fullWidth
                    defaultValue={dataEdit && dataEdit.proxy}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h6>Número do cartão</h6>
                  <TextField
                    inputRef={cardNumber}
                    size="small"
                    variant="outlined"
                    fullWidth
                    defaultValue={dataEdit && dataEdit.cardNum}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              style={{
                paddingRight: 20,
                paddingLeft: 20,
              }}
            >
              <Grid item xs={12} sm={6}>
                <h6>Tipo</h6>
                <Select
                  fullWidth
                  styles={customStylesEdit}
                  classNamePrefix="select"
                  placeholder="Tipo do cartão"
                  value={typeCardSelected}
                  onChange={setTypeCardSelected}
                  isSearchable={false}
                  options={optionsCardType}
                  menuPortalTarget={document.body}
                />
              </Grid>
              <Grid item xs={12} sm={6} disabled>
                <h6>Emissor</h6>
                <TextField
                  inputRef={emissor}
                  size="small"
                  variant="outlined"
                  fullWidth
                  defaultValue={dataEdit?.all?.issuer}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h6>Validade</h6>
                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    size="small"
                    format="yyyy-MM-dd"
                    fullWidth
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
          {/* </form> */}
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="secondary">
              Cancelar
            </Button>
            <Button
              // onClick={() => setOpen(false)}
              type="submit"
              color="primary"
              variant="contained"
              autoFocus
            >
              Salvar alterações
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
