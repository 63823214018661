/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import axios from 'axios'
// import FileDownload from 'js-file-download'

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import StepperContext from '../../context/StepperContext';
import SuccessModal from '../SuccessModal';
import { CARD_BRANDS, PRODUCTS } from '../../util/constants';
import FlashContext from '../../../../components/contexts/FlashContext';
import api from '../../../../service/axios/api';
// import Contract from '@/types/Contract';

// import CommercialAgent from '@/types/CommercialAgent';

const useStyles = makeStyles(theme => ({
  form: {
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    padding: theme.spacing(4),
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
    background: '#f0f0f0',
    width: '100%',
    '& > *': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function ContractReviewForm({ contract, agent, duplicate, agentComission }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const stepper = useContext(StepperContext);
  const { notifyError } = useContext(FlashContext);
  const [loading, setLoading] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [saveId, setSaveId] = useState('');

  const resolvAgents = async (contractId, ag, comission) => {
    await api.contract.linkAgent(contractId, ag, Number(comission));
  };

  const submit = async event => {
    event.preventDefault();
    setLoading(true);
    console.log('aaaaaaaaa')
    console.log(contract);

    
    try {
      // eslint-disable-next-line no-console
      const response = await stepper.onSuccess(contract);
      console.log("cheguei")
      // NOVA FORMA
      if (response.status === stepper.code || response.code === stepper.code) {
        console.log("cheguei")
        try {
          if (contract.id) {
            await api.contract.unbidAgent(contract.id);
          }
        } catch (e) {
          console.log(e);
        }
        // popuar com os novos
        for (let i = 0; i < agent.length; i++) {
          await resolvAgents(
            response?.data?.retorno?.contract_id || contract.id,
            agent[i].value,
            agent[i].agentComission,
            
          );
        }
        setOpenSuccessModal(true);
      }
      if (duplicate) {
        
        if (response?.data?.code === stepper.code) {
          if (agent) {
            await api.contract.linkAgent(
              response.data.retorno.contract_id,
              agent?.value,
              Number(agentComission),
              
            );
            setOpenSuccessModal(true);
          } else {
            await api.contract.unbidAgent(response.data.retorno.contract_id);
          }
          setOpenSuccessModal(true);
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      const error = err;
      notifyError(error.message);
      /* eslint no-console: ["error", { allow: ["error"] }] */
     }

    setLoading(false);
  };

  const handleCloseModal = () => {
    setOpenSuccessModal(false);
    stepper.actionModal();
  };

  const { company, productDetails } = contract;
  const { campaign } = productDetails;

  const { commercialAgent } = productDetails;

  const download = async () => {
    try {
      window.location.href = `/api/contracts/${saveId}/download`;
    } catch (err) {
      const error = err;

      // console.log(error)
    }
  };

  return (
    <>
      <form className={classes.form} onSubmit={submit}>
        <Container className={classes.content}>
          <Typography variant="h5" component="h3" gutterBottom>
            {t('newContract.summary.label')}
          </Typography>

          <Box bgcolor="background.gray" p={6}>
            <Grid container spacing={5}>
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  {t('newContract.summary.rz')}
                </Typography>
                <Typography variant="body2" color="initial">
                  {company.tradingName}
                </Typography>
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  {t('newContract.summary.product')}
                </Typography>
                <Typography variant="body2" color="initial">
                  {productDetails.product === 'transfer' &&
                    t('newContract.summary.transfer')}
                  {PRODUCTS.find(p => p.value === productDetails.productBrand)
                    ?.label || ''}{' '}
                  {
                    CARD_BRANDS.find(
                      brand => brand.value === productDetails.productBrand,
                    )?.label
                  }
                </Typography>
              </Grid>
              {productDetails.product === 'prepaid_card' && (
                <Grid item lg={4} sm={6} xs={12}>
                  <Typography variant="subtitle1" color="initial">
                    {t('newContract.summary.cardValue')}:
                  </Typography>
                  <Typography variant="body2" color="initial">
                    {campaign.issueFee}
                  </Typography>
                </Grid>
              )}
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  {t('newContract.summary.tradingName')}:
                </Typography>
                <Typography variant="body2" color="initial">
                  {company.tradingName}
                </Typography>
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  {t('newContract.summary.tax')}:
                </Typography>
                <Typography variant="body2" color="initial">
                  {campaign.isAdministrationRate
                    ? `${campaign.administrationRate}%`
                    : `R$ ${campaign?.administrationFee}`}
                </Typography>
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  {t('newContract.summary.cnpj')}:
                </Typography>
                <Typography variant="body2" color="initial">
                  {company.cnpj}
                </Typography>
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  {t('newContract.summary.campaign')}:
                </Typography>
                <Typography variant="body2" color="initial">
                  {company?.campaign?.label}
                </Typography>
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" color="initial">
                  {t('newContract.summary.salesAgents')}:
                </Typography>
                <Typography variant="body2" color="initial">
                  {commercialAgent.company?.legalName}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <div className={classes.footer}>
          <Button onClick={stepper.prev} type="button" variant="contained">
            {t('newContract.summary.goBackBtn')}
          </Button>
          <div className={classes.wrapper}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {t('newContract.summary.btnSalve')}
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </div>
      </form>
      <SuccessModal
        title={stepper.titleModal}
        open={openSuccessModal}
        onClose={handleCloseModal}
        onDownload={download}
      />
    </>
  );
}

export default ContractReviewForm;
