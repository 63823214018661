const campaign = {
  name: '',
  rechargeFrequency: '12',
  annualEstimatedAward: 'R$ 0,00',
  totalCommissionRate: 0,
  isAdministrationRate: true,
  // only when is_administration_rate is true
  administrationRate: '5.00',
  // only when is_administration_rate is false
  administrationFee: 'R$ 0,00',
  // only when product category is a card
  issueFee: 'R$ 0,00',
  virtualIssueFee: 'R$ 0,00',
};

export const operatorFees = {
  // For virtual_card and transfer
  isChanged: false,
  emitionTicket: 'R$ 3,50',
  pixPf: 'R$ 6,00',
  pixPj: 'R$ 6,00',
  lotteryDeposit: 'R$ 0,00',
  lotteryWithdrawal: 'R$ 0,00',
  PDV: '0,00',
  aditionalCard: 'R$ 0,00',
  expiredCard: 'R$ 0,00',
  codeQR: 'R$ 8,00',
  TED: 'R$ 9,00',
  SMS: 'R$ 0,00',
  // only for cards
  monthlyFee: 'R$ 0,00',
  unlockFee: 'R$ 0,00',
  
  virtualMonthlyFee: 'R$ 0,00',
  virtualUnlockFee: 'R$ 0,00',
  virtualReissueFee: 'R$ 0,00',
  virtualChargebackFee: 'R$ 0,00',
  virtualAtmWithdrawFee: 'R$ 0,00',
  virtualRechargePortalFee: 'R$ 0,00',
  virtualRechargeInvoiceFee: 'R$ 0,00',
  virtualP2pTransferFee: 'R$ 0,00',
  virtualMarkupRate: '0',
  reissueFee: 'R$ 0,00',
  chargebackFee: 'R$ 0,00',
  atmWithdrawFee: 'R$ 0,00',
  markupRate: '0,00',
  rechargePortalFee: 'R$ 0,00',
  rechargeInvoiceFee: 'R$ 0,00',
  p2pTransferFee: 'R$ 0,00',
  // only for transfers
  transferFee: 'R$ 0,00',
  rejectedTransactionFee: 'R$ 0,00',
  // only for checks
  checkFee: 'R$ 0,00',
  // only for vouchers
  voucherFee: 'R$ 0,00',
};

export const companyOperatingFees = {
  isChanged: false,
  KYC: 'R$ 0,00',
  balanceTransferFee: 'R$ 0,00',
  minimumLoadAmount: 'R$ 0,00',
  belowMinimumLoadFee: 'R$ 0,00',
  emergencyLoadFee: 'R$ 0,00',
  specialHandlingFee: 'R$ 0,00',
  chargebackRate: '0,00',
  rejectedTransactionFee: 'R$ 0,00',
  ir_fee: 'R$ 8,50',
  
  // isVirtualCard: false,
  virtualBalanceTransferFee: 'R$ 0,00',
  virtualMinimumLoadAmount: 'R$ 0,00',
  virtualBelowMinimumLoadFee: 'R$ 0,00',
  virtualEmergencyLoadFee: 'R$ 0,00',
  virtualSpecialHandlingFee: 'R$ 0,00',
  virtualChargebackRate: '0',
  virtualRejectedTransactionFee: 'R$ 0,00',
  virtualIrFee: 'R$ 0,00',
  virtualIssueFee: 'R$ 0,00',
};

export const cardFees = {
  physicalCard: 'R$ 8,50',
  sendCard: 'R$ 0,00',
  cardWithdrawal: 'R$ 0,00',
};

const commercialAgent = {
  id: 'CsFaIoewgVLlz5mZOgSI',
  company: {
    admin: {
      companyArea: 'venda',
      companyRole: 'vendedor',
      cpf: '11111111111',
      fullName: 'John Doe',
      email: 'churi410@gmail.com',
      mobile: '5804269900709',
      phone: '5804269900709',
    },
    cnpj: '11111111111111',
    comments: '',
    email: 'churi410@gmail.com',
    legalName: ' Element Marketing e Participações',
    tradingName: ' Element Marketing e Participações',
    mobile: '5804269900709',
    phone: '5804269900709',
    website: 'www.churi.com.br',
  },
  salesCommissionId: 'ga3ze2XuOSX7FsD9T80e',
};

export const productDetails = {
  operatorFees,
  cardFees,
  campaign,
  product: 'prepaid_card',
  productBrand: 'mastercard',
  commercialAgent,
  commission_calculation: 'total',
  // only for cards
  companyOperatingFees,
  salesChannel: 'direct',
  classAccount: 'basic',
  isGiftCard: false,
  kindAccount: 'virtual_card',
  isInternationalCard: true,
  cardQuantity: 1,
  isChipCard: true,
  issuer: 'acesso',
  typeCard: '1',
};

export default productDetails;
