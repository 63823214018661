/* eslint-disable react/prop-types */
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

import CheckboxForm from '../../common/Checkbox';
import {
  ISSUERS,
  ISSUERS_MC,
  ISSUERS_VISA,
  CARD_BRANDS_PRODUCT,
} from '../../../util/constants';

function PrepaidCardLeftViewer({ form }) {
  const {
    register,
    watch,
    control,
    getValues,
    formState: { errors },
  } = form;

  const { t } = useTranslation();

  const { productBrand } = watch();

  return (
    <>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Controller
          control={control}
          name="issuer"
          render={props => (
            <TextField
              onChange={e => props.field.onChange(e.target.value)}
              value={props.field.value}
              fullWidth
              variant="outlined"
              size="small"
              select
              label={t('newContract.product.issuer')}
              error={!!errors.issuer}
              helperText={errors.issuer?.message}
              disabled
            >
              {productBrand === 'mastercard' &&
                ISSUERS_MC.map(issuer => (
                  <MenuItem key={issuer.value} value={issuer.value}>
                    {issuer.label}
                  </MenuItem>
                ))}
              {productBrand === 'visa' &&
                ISSUERS_VISA.map(issuer => (
                  <MenuItem key={issuer.value} value={issuer.value}>
                    {issuer.label}
                  </MenuItem>
                ))}
              {!(productBrand === 'visa' || productBrand === 'mastercard') &&
                ISSUERS.map(issuer => (
                  <MenuItem key={issuer.value} value={issuer.value}>
                    {issuer.label}
                  </MenuItem>
                ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="productBrand"
          render={props => (
            <TextField
              onChange={e => props.field.onChange(e.target.value)}
              value={props.field.value}
              variant="outlined"
              select
              size="small"
              label={t('newContract.product.brand')}
              defaultValue=""
              error={!!errors.productBrand}
              helperText={errors.productBrand?.message}
              fullWidth
              disabled
            >
              {CARD_BRANDS_PRODUCT.prepaidCard.map(brand => (
                <MenuItem key={brand.id} value={brand.value}>
                  {brand.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl
          component="fieldset"
          size="small"
          error={!!errors.isChipCard}
        >
          <FormLabel component="legend">
            {t('newContract.product.has')}
          </FormLabel>
          <Controller
            control={control}
            name="isChipCard"
            render={props => (
              <RadioGroup
                onChange={e => {
                  props.field.onChange(e.target.value === 'true');
                }}
                value={String(props.field.value)}
                aria-label="Possui"
                row
              >
                <FormControlLabel
                  value="true"
                  disabled
                  control={<Radio />}
                  label={`${t('newContract.product.chip')}/NFC`}
                />
                <FormControlLabel
                  value="false"
                  disabled
                  control={<Radio />}
                  label="Tarja"
                />
              </RadioGroup>
            )}
          />
          <FormHelperText>{errors.isChipCard?.message}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormLabel component="legend">
          {t('newContract.product.card')}
        </FormLabel>
        <FormControl
          component="fieldset"
          size="small"
          error={!!errors.isInternationalCard}
        >
          <Controller
            control={control}
            name="isInternationalCard"
            render={props => (
              <RadioGroup
                onChange={e => {
                  props.field.onChange(e.target.value === 'true');
                }}
                value={String(props.field.value)}
                aria-label="Possui"
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio disabled />}
                  label={t('newContract.product.international')}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio disabled />}
                  label={t('newContract.product.national')}
                />
              </RadioGroup>
            )}
          />
          <FormHelperText>{errors.isInternationalCard?.message}</FormHelperText>
        </FormControl>
        <FormControlLabel
          label={t('newContract.product.gift')}
          labelPlacement="end"
          style={{ float: 'right' }}
          control={
            <CheckboxForm
              {...register('isGiftCard')}
              defaultValue={getValues('isGiftCard')}
              disabled
            />
            
          }
        />
      </Grid>
    </>
  );
}

export default PrepaidCardLeftViewer;
