/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  makeStyles,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { NumberFormatCustom, NumberFormatRate } from '../../NumberFormatCustom';
import cardFees from '../defaultStates/cardFees';

const useStyles = makeStyles({
  commissions: {
    background: 'white',
  },
});

function PrepaidCardRightViewer({ form }) {
  const {
    register,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = form;
  const classes = useStyles();
  const { t } = useTranslation();

  // console.log('TARIFAS operatorFees', getValues('operatorFees'));
  // console.log('TARIFAS DEFAULT', cardFees.default.operatorFees);
  const [selectionCard, setSelectionCard] = useState(getValues('typeCard'))

  const { productBrand } = watch();
  const savedCard = watch('typeCard');
  // const [operatorFee, setOperatorFee] = useState(cardFees.default.operatorFees);

  const [operatorFee, setOperatorFee] = useState(getValues('operatorFees'));
  const [companyOperatingFee, setCompanyOperatingFee] = useState(
    getValues('companyOperatingFees'),
  );

  useEffect(() => {
    if (savedCard) {
      setSelectionCard(String(savedCard))
    }
  }, [savedCard])
  // useMemo(() => {
  //   console.log("User has changed");
  //   return props.user;
  // }, [props])

  function handleChange(op) {
    if (op === 'companyOperatingFees.balanceTransferFee') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          balanceTransferFee: String(e.target.value),
        });
      };
    }
    if (op === 'companyOperatingFees.minimumLoadAmount') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          minimumLoadAmount: String(e.target.value),
        });
      };
    }
    if (op === 'companyOperatingFees.belowMinimumLoadFee') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          belowMinimumLoadFee: String(e.target.value),
        });
      };
    }
    if (op === 'companyOperatingFees.emergencyLoadFee') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          emergencyLoadFee: String(e.target.value),
        });
      };
    }
    if (op === 'companyOperatingFees.specialHandlingFee') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          specialHandlingFee: String(e.target.value),
        });
      };
    }
    if (op === 'companyOperatingFees.ir_fee') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          ir_fee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.monthlyFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          monthlyFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.unlockFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          unlockFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.reissueFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          reissueFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.chargebackFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          chargebackFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.atmWithdrawFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          atmWithdrawFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.rechargePortalFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          rechargePortalFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.rechargeInvoiceFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          rechargeInvoiceFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.p2pTransferFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          p2pTransferFee: String(e.target.value),
        });
      };
    }
    if (op === 'companyOperatingFees.virtualBalanceTransferFee') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          virtualBalanceTransferFee: String(e.target.value),
        });
      };
    }
    if (op === 'companyOperatingFees.virtualMinimumLoadAmount') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          virtualMinimumLoadAmount: String(e.target.value),
        });
      };
    }
    if (op === 'companyOperatingFees.virtualBelowMinimumLoadFee') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          virtualBelowMinimumLoadFee: String(e.target.value),
        });
      };
    }
    if (op === 'companyOperatingFees.virtualEmergencyLoadFee') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          virtualEmergencyLoadFee: String(e.target.value),
        });
      };
    }
    if (op === 'companyOperatingFees.virtualSpecialHandlingFee') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          virtualSpecialHandlingFee: String(e.target.value),
        });
      };
    }
    if (op === 'companyOperatingFees.virtualIssueFee') {
      return e => {
        setCompanyOperatingFee({
          ...companyOperatingFee,
          virtualIssueFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.virtualMonthlyFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          virtualMonthlyFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.virtualUnlockFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          virtualUnlockFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.virtualReissueFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          virtualReissueFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.virtualChargebackFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          virtualChargebackFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.virtualAtmWithdrawFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          virtualAtmWithdrawFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.virtualRechargePortalFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          virtualRechargePortalFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.virtualRechargeInvoiceFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          virtualRechargeInvoiceFee: String(e.target.value),
        });
      };
    }
    if (op === 'operatorFees.virtualP2pTransferFee') {
      return e => {
        setOperatorFee({
          ...operatorFee,
          virtualP2pTransferFee: String(e.target.value),
        });
      };
    }

    return e => {
      setOperatorFee({
        ...operatorFee,
      });
    };
  }

  useEffect(() => {
    switch (productBrand) {
      case 'mastercard':
        // setValue('operatorFees', cardFees.mastercard.operatorFees);
        if (operatorFee.isChanged) {
          setValue('operatorFees', operatorFee);
          setOperatorFee(getValues('operatorFees'));
        } else {
          setValue('operatorFees', cardFees.mastercard.operatorFees);
          setOperatorFee(cardFees.mastercard.operatorFees);
        }

        if (companyOperatingFee.isChanged) {
          setCompanyOperatingFee(getValues('companyOperatingFees'));
          setValue('companyOperatingFees', companyOperatingFee);
        } else {
          setCompanyOperatingFee(cardFees.mastercard.companyOperatingFees);
          setValue(
            'companyOperatingFees',
            cardFees.mastercard.companyOperatingFees,
          );
        }
        // setOperatorFee(cardFees.mastercard.operatorFees);
        // setOperatorFee(cardFees.mastercard.operatorFees);
        break;
      case 'visa':
        if (operatorFee.isChanged) {
          setValue('operatorFees', operatorFee);
          setOperatorFee(operatorFee);
        } else {
          setValue('operatorFees', cardFees.visa.operatorFees);
          setOperatorFee(cardFees.visa.operatorFees);
        }
        if (companyOperatingFee.isChanged) {
          setValue('companyOperatingFees', companyOperatingFee);
          setCompanyOperatingFee(companyOperatingFee);
        } else {
          setValue('companyOperatingFees', cardFees.visa.companyOperatingFees);
          setCompanyOperatingFee(cardFees.visa.companyOperatingFees);
        }
        break;
      default:
        if (operatorFee.isChanged) {
          setValue('operatorFees', operatorFee);
        } else {
          setValue('operatorFees', cardFees.default.operatorFees);
          setOperatorFee(cardFees.default.operatorFees);
        }

        if (companyOperatingFee.isChanged) {
          setValue('companyOperatingFees', companyOperatingFee);
          setCompanyOperatingFee(companyOperatingFee);
        } else {
          setValue(
            'companyOperatingFees',
            cardFees.default.companyOperatingFees,
          );
          setCompanyOperatingFee(cardFees.visa.companyOperatingFees);
        }
        break;
    }
  }, [productBrand]);

  const changeStatus = () => {
    setValue('operatorFees.isChanged', true);
    setOperatorFee({
      ...operatorFee,
      isChanged: true,
    });
  };

  const changeCompanyStatus = () => {
    setValue('companyOperatingFees.isChanged', true);
    setCompanyOperatingFee({
      ...companyOperatingFee,
      isChanged: true,
    });
  };

  return (
    <Grid className={classes.commissions} item lg={12} md={12} sm={12} xs={12}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 30,
          flex: 1,
        }}
      >
        <Typography
          className={classes.header}
          variant="h5"
          component="h3"
          gutterBottom
        >
          {/* {t('newContract.product.label3')}
           */}
          Tipo de cartão
        </Typography>

        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <FormControl>
            {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
            <Controller
              name="typeCard"
              control={control}
              defaultValue=""
              render={({ field }) => {
                return <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  row
                  value={field.value?.toString()}
                  onChange={field.onChange}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <FormControlLabel value='1' control={<Radio disabled />} label="Cartão físico" />
                    <FormControlLabel value='2' control={<Radio disabled />} label="Cartão virtual" />
                    <FormControlLabel value='3' control={<Radio disabled />} label="Ambos os cartões" />
                  </div>
                </RadioGroup>
              }}
            />
          </FormControl>
        </div>
      </div>
      {(selectionCard === '1' || selectionCard === '3') && (
        <>
          <div style={{ paddingTop: 20 }}>
            <Box
              p={{ xs: 3, sm: 3, md: 4, lg: 5 }}
              mb={{ xs: 0, sm: 0, md: 0, lg: 0 }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    className={classes.header}
                    variant="h5"
                    component="h3"
                    gutterBottom
                  >
                    {t('newContract.product.label1')} - cartão físico
                  </Typography>
                </Grid>

                <Grid item lg={4} md={4} sm={3} xs={6}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                    error={!!errors.companyOperatingFees?.balanceTransferFee}
                    helperText={
                      errors.companyOperatingFees?.balanceTransferFee?.message ||
                      t('newContract.product.cTransferFee')
                    }
                    value={companyOperatingFee.balanceTransferFee}
                    onChange={handleChange(
                      'companyOperatingFees.balanceTransferFee',
                    )}
                    onClick={changeCompanyStatus}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: {
                        ...register('companyOperatingFees.balanceTransferFee'),
                      },
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={3} xs={6}>
                  <TextField
                    disabled
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={!!errors.companyOperatingFees?.minimumLoadAmount}
                    helperText={
                      errors.companyOperatingFees?.minimumLoadAmount?.message ||
                      t('newContract.product.cMinCharge')
                    }
                    value={companyOperatingFee.minimumLoadAmount}
                    onChange={handleChange(
                      'companyOperatingFees.minimumLoadAmount',
                    )}
                    onClick={changeCompanyStatus}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: {
                        ...register('companyOperatingFees.minimumLoadAmount'),
                      },
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={3} xs={6}>
                  <TextField
                    disabled
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={!!errors.companyOperatingFees?.belowMinimumLoadFee}
                    helperText={
                      errors.companyOperatingFees?.belowMinimumLoadFee?.message ||
                      t('newContract.product.cMinChargeFee')
                    }
                    value={companyOperatingFee.belowMinimumLoadFee}
                    onChange={handleChange(
                      'companyOperatingFees.belowMinimumLoadFee',
                    )}
                    onClick={changeCompanyStatus}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: {
                        ...register('companyOperatingFees.belowMinimumLoadFee'),
                      },
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={3} xs={6}>
                  <TextField
                    disabled
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={!!errors.companyOperatingFees?.emergencyLoadFee}
                    helperText={
                      errors.companyOperatingFees?.emergencyLoadFee?.message ||
                      t('newContract.product.cEmergencyCharge')
                    }
                    value={companyOperatingFee.emergencyLoadFee}
                    onChange={handleChange('companyOperatingFees.emergencyLoadFee')}
                    onClick={changeCompanyStatus}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: {
                        ...register('companyOperatingFees.emergencyLoadFee'),
                      },
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={3} xs={6}>
                  <TextField
                    disabled
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={!!errors.companyOperatingFees?.specialHandlingFee}
                    helperText={
                      errors.companyOperatingFees?.specialHandlingFee?.message ||
                      t('newContract.product.cHandling')
                    }
                    value={companyOperatingFee.specialHandlingFee}
                    onChange={handleChange(
                      'companyOperatingFees.specialHandlingFee',
                    )}
                    onClick={changeCompanyStatus}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: {
                        ...register('companyOperatingFees.specialHandlingFee'),
                      },
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={3} xs={6}>
                  <TextField
                    disabled
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={!!errors.companyOperatingFees?.chargebackRate}
                    helperText={
                      errors.companyOperatingFees?.chargebackRate?.message ||
                      t('newContract.product.cChargebackFee')
                    }
                    InputProps={{
                      inputComponent: NumberFormatRate,
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        ...register('companyOperatingFees.chargebackRate'),
                      },
                    }}
                    value={companyOperatingFee.chargebackRate}
                    onChange={e => {
                      setCompanyOperatingFee({
                        ...companyOperatingFee,
                        chargebackRate: e.target.value,
                      });
                    }}
                    onClick={changeCompanyStatus}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={3} xs={6}>
                  <TextField
                    disabled
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={!!errors.companyOperatingFees?.ir_fee}
                    helperText={
                      errors.companyOperatingFees?.ir_fee?.message || 'DCP'
                    }
                    value={companyOperatingFee.ir_fee}
                    onChange={handleChange('companyOperatingFees.ir_fee')}
                    onClick={changeCompanyStatus}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: {
                        ...register('companyOperatingFees.ir_fee'),
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </div>

          <Box
            p={{ xs: 3, sm: 3, md: 4, lg: 5 }}
            mb={{ xs: 0, sm: 0, md: 0, lg: 0 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  className={classes.header}
                  variant="h5"
                  component="h3"
                  gutterBottom
                >
                  {t('newContract.product.label2')} - cartão físico
                </Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={3} xs={6}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.operatorFees?.monthlyFee}
                  helperText={
                    errors.operatorFees?.monthlyFee?.message ||
                    t('newContract.product.oMonthlyPayment')
                  }
                  //
                  value={operatorFee.monthlyFee}
                  onChange={handleChange('operatorFees.monthlyFee')}
                  onClick={changeStatus}
                  //

                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      ...register('operatorFees.monthlyFee'),
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={3} xs={6}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.operatorFees?.unlockFee}
                  helperText={
                    errors.operatorFees?.unlockFee?.message ||
                    t('newContract.product.oUnlock')
                  }
                  value={operatorFee.unlockFee}
                  onChange={handleChange('operatorFees.unlockFee')}
                  onClick={changeStatus}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      ...register('operatorFees.unlockFee'),
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={3} xs={6}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.operatorFees?.reissueFee}
                  helperText={
                    errors.operatorFees?.reissueFee?.message ||
                    t('newContract.product.oDuplicate')
                  }
                  value={operatorFee.reissueFee}
                  onChange={handleChange('operatorFees.reissueFee')}
                  onClick={changeStatus}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      ...register('operatorFees.reissueFee'),
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={3} xs={6}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.operatorFees?.chargebackFee}
                  helperText={
                    errors.operatorFees?.chargebackFee?.message ||
                    t('newContract.product.oChargeback')
                  }
                  value={operatorFee.chargebackFee}
                  onChange={handleChange('operatorFees.chargebackFee')}
                  onClick={changeStatus}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      ...register('operatorFees.chargebackFee'),
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={3} xs={6}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.operatorFees?.atmWithdrawFee}
                  helperText={
                    errors.operatorFees?.atmWithdrawFee?.message ||
                    t('newContract.product.oWithdraw')
                  }
                  value={operatorFee.atmWithdrawFee}
                  onChange={handleChange('operatorFees.atmWithdrawFee')}
                  onClick={changeStatus}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      ...register('operatorFees.atmWithdrawFee'),
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={3} xs={6}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.operatorFees?.markupRate}
                  helperText={
                    errors.operatorFees?.markupRate?.message ||
                    t('newContract.product.oMarkupFee')
                  }
                  InputProps={{
                    inputComponent: NumberFormatRate,
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    inputProps: {
                      ...register('operatorFees.markupRate'),
                    },
                  }}
                  value={operatorFee.markupRate}
                  onChange={e => {
                    setOperatorFee({
                      ...operatorFee,
                      markupRate: e.target.value,
                    });
                  }}
                  onClick={changeStatus}
                />
              </Grid>
            </Grid>
          </Box>
        </>)}
      {(selectionCard === '2' || selectionCard === '3') && (
        <>
          <Box
            p={{ xs: 3, sm: 3, md: 4, lg: 5 }}
            mb={{ xs: 0, sm: 0, md: 0, lg: 0 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  className={classes.header}
                  variant="h5"
                  component="h3"
                  gutterBottom
                >
                  {t('newContract.product.label1')} - cartão virtual
                </Typography>
              </Grid>

              <Grid item lg={4} md={4} sm={3} xs={6}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.companyOperatingFees?.virtualBalanceTransferFee}
                  helperText={
                    errors.companyOperatingFees?.virtualBalanceTransferFee
                      ?.message || t('newContract.product.cTransferFee')
                  }
                  value={companyOperatingFee.virtualBalanceTransferFee}
                  onChange={handleChange(
                    'companyOperatingFees.virtualBalanceTransferFee',
                  )}
                  onClick={changeCompanyStatus}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      ...register('companyOperatingFees.virtualBalanceTransferFee'),
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={3} xs={6}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.companyOperatingFees?.virtualMinimumLoadAmount}
                  helperText={
                    errors.companyOperatingFees?.virtualMinimumLoadAmount
                      ?.message || t('newContract.product.cMinCharge')
                  }
                  value={companyOperatingFee.virtualMinimumLoadAmount}
                  onChange={handleChange(
                    'companyOperatingFees.virtualMinimumLoadAmount',
                  )}
                  onClick={changeCompanyStatus}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      ...register('companyOperatingFees.virtualMinimumLoadAmount'),
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={3} xs={6}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.companyOperatingFees?.virtualBelowMinimumLoadFee}
                  helperText={
                    errors.companyOperatingFees?.virtualBelowMinimumLoadFee
                      ?.message || t('newContract.product.cMinChargeFee')
                  }
                  value={companyOperatingFee.virtualBelowMinimumLoadFee}
                  onChange={handleChange(
                    'companyOperatingFees.virtualBelowMinimumLoadFee',
                  )}
                  onClick={changeCompanyStatus}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      ...register(
                        'companyOperatingFees.virtualBelowMinimumLoadFee',
                      ),
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={3} xs={6}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.companyOperatingFees?.virtualEmergencyLoadFee}
                  helperText={
                    errors.companyOperatingFees?.virtualEmergencyLoadFee?.message ||
                    t('newContract.product.cEmergencyCharge')
                  }
                  value={companyOperatingFee.virtualEmergencyLoadFee}
                  onChange={handleChange(
                    'companyOperatingFees.virtualEmergencyLoadFee',
                  )}
                  onClick={changeCompanyStatus}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      ...register('companyOperatingFees.virtualEmergencyLoadFee'),
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={3} xs={6}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.companyOperatingFees?.virtualSpecialHandlingFee}
                  helperText={
                    errors.companyOperatingFees?.virtualSpecialHandlingFee
                      ?.message || t('newContract.product.cHandling')
                  }
                  value={companyOperatingFee.virtualSpecialHandlingFee}
                  onChange={handleChange(
                    'companyOperatingFees.virtualSpecialHandlingFee',
                  )}
                  onClick={changeCompanyStatus}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      ...register('companyOperatingFees.virtualSpecialHandlingFee'),
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={3} xs={6}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.companyOperatingFees?.virtualChargebackRate}
                  helperText={
                    errors.companyOperatingFees?.virtualChargebackRate?.message ||
                    t('newContract.product.cChargebackFee')
                  }
                  InputProps={{
                    inputComponent: NumberFormatRate,
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    inputProps: {
                      ...register('companyOperatingFees.virtualChargebackRate'),
                    },
                  }}
                  value={companyOperatingFee.virtualChargebackRate}
                  onChange={e => {
                    setCompanyOperatingFee({
                      ...companyOperatingFee,
                      virtualChargebackRate: e.target.value,
                    });
                  }}
                  onClick={changeCompanyStatus}
                />
              </Grid>

              <Grid
                item
                lg={4}
                md={4}
                sm={3}
                xs={6}
                style={{ marginRight: '50px' }}
              >
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.companyOperatingFees?.virtualIssueFee}
                  helperText={
                    errors.companyOperatingFees?.virtualIssueFee?.message ||
                    'Taxa de emissão'
                  }
                  value={companyOperatingFee.virtualIssueFee}
                  onChange={handleChange('companyOperatingFees.virtualIssueFee')}
                  onClick={changeCompanyStatus}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      ...register('companyOperatingFees.virtualIssueFee'),
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box
            p={{ xs: 3, sm: 3, md: 4, lg: 5 }}
            mb={{ xs: 0, sm: 0, md: 0, lg: 0 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  className={classes.header}
                  variant="h5"
                  component="h3"
                  gutterBottom
                >
                  {t('newContract.product.label2')} - cartão virtual
                </Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={3} xs={6}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.operatorFees?.virtualMonthlyFee}
                  helperText={
                    errors.operatorFees?.virtualMonthlyFee?.message ||
                    t('newContract.product.oMonthlyPayment')
                  }
                  value={operatorFee.virtualMonthlyFee}
                  onChange={handleChange('operatorFees.virtualMonthlyFee')}
                  onClick={changeStatus}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      ...register('operatorFees.virtualMonthlyFee'),
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={3} xs={6}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.operatorFees?.virtualUnlockFee}
                  helperText={
                    errors.operatorFees?.virtualUnlockFee?.message ||
                    t('newContract.product.oUnlock')
                  }
                  value={operatorFee.virtualUnlockFee}
                  onChange={handleChange('operatorFees.virtualUnlockFee')}
                  onClick={changeStatus}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      ...register('operatorFees.virtualUnlockFee'),
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={3} xs={6}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.operatorFees?.virtualReissueFee}
                  helperText={
                    errors.operatorFees?.virtualReissueFee?.message ||
                    t('newContract.product.oDuplicate')
                  }
                  value={operatorFee.virtualReissueFee}
                  onChange={handleChange('operatorFees.virtualReissueFee')}
                  onClick={changeStatus}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      ...register('operatorFees.virtualReissueFee'),
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={3} xs={6}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.operatorFees?.virtualChargebackFee}
                  helperText={
                    errors.operatorFees?.virtualChargebackFee?.message ||
                    t('newContract.product.oChargeback')
                  }
                  value={operatorFee.virtualChargebackFee}
                  onChange={handleChange('operatorFees.virtualChargebackFee')}
                  onClick={changeStatus}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      ...register('operatorFees.virtualChargebackFee'),
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={3} xs={6}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.operatorFees?.virtualAtmWithdrawFee}
                  helperText={
                    errors.operatorFees?.virtualAtmWithdrawFee?.message ||
                    t('newContract.product.oWithdraw')
                  }
                  value={operatorFee.virtualAtmWithdrawFee}
                  onChange={handleChange('operatorFees.virtualAtmWithdrawFee')}
                  onClick={changeStatus}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      ...register('operatorFees.virtualAtmWithdrawFee'),
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={3} xs={6}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!errors.operatorFees?.virtualMarkupRate}
                  helperText={
                    errors.operatorFees?.virtualMarkupRate?.message ||
                    t('newContract.product.oMarkupFee')
                  }
                  InputProps={{
                    inputComponent: NumberFormatRate,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                    inputProps: {
                      ...register('operatorFees.virtualMarkupRate'),
                    },
                  }}
                  value={operatorFee.virtualMarkupRate}
                  onChange={e => {
                    setOperatorFee({
                      ...operatorFee,
                      virtualMarkupRate: e.target.value,
                    });
                  }}
                  onClick={changeStatus}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {/* <Box
        p={{ xs: 3, sm: 3, md: 4, lg: 5 }}
        mb={{ xs: 3, sm: 3, md: 4, lg: 5 }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="primary"
              paragraph
              align="center"
            >
              {t('newContract.product.label1')}
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.companyOperatingFees?.balanceTransferFee}
              helperText={
                errors.companyOperatingFees?.balanceTransferFee?.message ||
                t('newContract.product.cTransferFee')
              }
              value={companyOperatingFee.balanceTransferFee}
              onChange={handleChange('companyOperatingFees.balanceTransferFee')}
              onClick={changeCompanyStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('companyOperatingFees.balanceTransferFee'),
                },
              }}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.companyOperatingFees?.minimumLoadAmount}
              helperText={
                errors.companyOperatingFees?.minimumLoadAmount?.message ||
                t('newContract.product.cMinCharge')
              }
              value={companyOperatingFee.minimumLoadAmount}
              onChange={handleChange('companyOperatingFees.minimumLoadAmount')}
              onClick={changeCompanyStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('companyOperatingFees.minimumLoadAmount'),
                },
              }}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.companyOperatingFees?.belowMinimumLoadFee}
              helperText={
                errors.companyOperatingFees?.belowMinimumLoadFee?.message ||
                t('newContract.product.cMinChargeFee')
              }
              value={companyOperatingFee.belowMinimumLoadFee}
              onChange={handleChange(
                'companyOperatingFees.belowMinimumLoadFee',
              )}
              onClick={changeCompanyStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('companyOperatingFees.belowMinimumLoadFee'),
                },
              }}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.companyOperatingFees?.emergencyLoadFee}
              helperText={
                errors.companyOperatingFees?.emergencyLoadFee?.message ||
                t('newContract.product.cEmergencyCharge')
              }
              value={companyOperatingFee.emergencyLoadFee}
              onChange={handleChange('companyOperatingFees.emergencyLoadFee')}
              onClick={changeCompanyStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('companyOperatingFees.emergencyLoadFee'),
                },
              }}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.companyOperatingFees?.specialHandlingFee}
              helperText={
                errors.companyOperatingFees?.specialHandlingFee?.message ||
                t('newContract.product.cHandling')
              }
              value={companyOperatingFee.specialHandlingFee}
              onChange={handleChange('companyOperatingFees.specialHandlingFee')}
              onClick={changeCompanyStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('companyOperatingFees.specialHandlingFee'),
                },
              }}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.companyOperatingFees?.chargebackRate}
              helperText={
                errors.companyOperatingFees?.chargebackRate?.message ||
                t('newContract.product.cChargebackFee')
              }
              InputProps={{
                inputComponent: NumberFormatRate,
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: {
                  ...register('companyOperatingFees.chargebackRate'),
                },
              }}
              value={companyOperatingFee.chargebackRate}
              onChange={e => {
                setCompanyOperatingFee({
                  ...companyOperatingFee,
                  chargebackRate: e.target.value,
                });
              }}
              onClick={changeCompanyStatus}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.companyOperatingFees?.ir_fee}
              helperText={errors.companyOperatingFees?.ir_fee?.message || 'DCP'}
              value={companyOperatingFee.ir_fee}
              onChange={handleChange('companyOperatingFees.ir_fee')}
              onClick={changeCompanyStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('companyOperatingFees.ir_fee'),
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box p={{ xs: 3, sm: 3, md: 4, lg: 5 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="primary"
              paragraph
              align="center"
            >
              {t('newContract.product.label2')}
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.operatorFees?.monthlyFee}
              helperText={
                errors.operatorFees?.monthlyFee?.message ||
                t('newContract.product.oMonthlyPayment')
              }
              value={operatorFee.monthlyFee}
              onChange={handleChange('operatorFees.monthlyFee')}
              onClick={changeStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.monthlyFee'),
                },
              }}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.operatorFees?.unlockFee}
              helperText={
                errors.operatorFees?.unlockFee?.message ||
                t('newContract.product.oUnlock')
              }
              value={operatorFee.unlockFee}
              onChange={handleChange('operatorFees.unlockFee')}
              onClick={changeStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.unlockFee'),
                },
              }}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.operatorFees?.reissueFee}
              helperText={
                errors.operatorFees?.reissueFee?.message ||
                t('newContract.product.oDuplicate')
              }
              value={operatorFee.reissueFee}
              onChange={handleChange('operatorFees.reissueFee')}
              onClick={changeStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.reissueFee'),
                },
              }}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.operatorFees?.chargebackFee}
              helperText={
                errors.operatorFees?.chargebackFee?.message ||
                t('newContract.product.oChargeback')
              }
              value={operatorFee.chargebackFee}
              onChange={handleChange('operatorFees.chargebackFee')}
              onClick={changeStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.chargebackFee'),
                },
              }}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.operatorFees?.atmWithdrawFee}
              helperText={
                errors.operatorFees?.atmWithdrawFee?.message ||
                t('newContract.product.oWithdraw')
              }
              value={operatorFee.atmWithdrawFee}
              onChange={handleChange('operatorFees.atmWithdrawFee')}
              onClick={changeStatus}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  ...register('operatorFees.atmWithdrawFee'),
                },
              }}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={3} xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.operatorFees?.markupRate}
              helperText={
                errors.operatorFees?.markupRate?.message ||
                t('newContract.product.oMarkupFee')
              }
              InputProps={{
                inputComponent: NumberFormatRate,
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: {
                  ...register('operatorFees.markupRate'),
                },
              }}
              value={operatorFee.markupRate}
              onChange={e => {
                setOperatorFee({
                  ...operatorFee,
                  markupRate: e.target.value,
                });
              }}
              onClick={changeStatus}
              disabled
            />
          </Grid>
        </Grid>
      </Box> */}
    </Grid>
  );
}

export default PrepaidCardRightViewer;
