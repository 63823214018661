import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';

import {
  // TimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  // DateTimePicker,
  // KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/pt-BR';
import Button from '@material-ui/core/Button';
import api from '../../service/axios/api';
import RpsTable from './RpsTable';
import Loading from '../../components/loading/Loading';

export default function Rps() {
  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateIni, setDateIni] = useState(new Date());
  const [dateFim, setDateFim] = useState(new Date());
  const [page, setPage] = useState(0);
  const [itens, setItens] = useState(25);
  const [total, setTotal] = useState(0);

  const searchRps = () => {
    setLoading(true);
    const cancel = new AbortController();
    api.rps
      .getAll(
        itens,
        page + 1,
        `${moment(dateIni).format('YYYY-MM-DD')} 00:00:01`,
        `${moment(dateFim).format('YYYY-MM-DD')} 23:59:59`,
        cancel,
      )
      .then(res => {
        console.log(res.docs)
        setTotal(res.total);
        setDocs(res.docs);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    searchRps();
  }, [page, itens]);

  // const ini = `${moment(new Date()).format('YYYY-MM-DD')} 00:00:01`;
  // const fim = `${moment(new Date()).format('YYYY-MM-DD')} 23:59:59`;
  return (
    <>
      <Loading open={loading} msg="Buscando rpss..." />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <h1>RPSs</h1>
        </Grid>
        <Grid container spacing={1} alignItems="center" justify="center">
          <Grid item xs={12} sm={4}>
            {/* <h6>Data Inicial</h6> */}
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={locale}
              // className={classes.margin}
            >
              <KeyboardDatePicker
                fullWidth
                size="small"
                value={dateIni}
                inputVariant="outlined"
                placeholder="Data inicial"
                onChange={dat => setDateIni(dat)}
                autoOk
                format="dd/MM/yyyy"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            {/* <h6>Data Final</h6> */}
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={locale}
              // className={classes.margin}
            >
              <KeyboardDatePicker
                fullWidth
                size="small"
                value={dateFim}
                inputVariant="outlined"
                placeholder="Data final"
                onChange={dat => setDateFim(dat)}
                autoOk
                format="dd/MM/yyyy"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              autoFocus
              onClick={searchRps}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
          <RpsTable
            docs={docs}
            // setDocs={setDocs}
            page={page}
            setPage={setPage}
            itens={itens}
            setItens={setItens}
            total={total}
            search={searchRps}
          />
        </Grid>
      </Grid>
    </>
  );
}
