/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

// import { NextPageContext } from 'next';

import { Grid, makeStyles, Typography } from '@material-ui/core';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from '../../../../styles/Layout.module.css';
import api from '../../../../service/axios/api';
import ContractForm from '../../components/forms/ContractForm';
import company from '../../components/forms/defaultStates/company';
import { productDetails } from '../../components/forms/defaultStates/productDetails';
import SpinnerPage from '../../../../components/loading/Loading';
import routing from '../../../../service/routes/routing';
// import { privateFetch } from '@/util/fetch';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
  },
  selectField: {
    minWidth: '200px',
  },
}));

const revenue = {
  calculation: 'normal',
  currency: 'brl',
  paymentMethod: 'transfer',
  isInvoiceTopup: false,
  issuingCompanyId: 1,
  bankAccountId: 1,
  serviceCodeId: 8,
  signatureDate: new Date(),
  expirationDate: new Date(),
  isSigned: false,
};
const deliveryAddress = {
  postalCode: '',
  street: '',
  number: '',
  complement: '',
  district: '',
  city: '',
  state: '',
  receiver: '',
  deliveryAgent: 'logistics',
  isCentralizedDelivery: true,
  deliveryService: 'doc+',
};

const defaultContract = {
  id: '0',
  isActive: true,
  pdfKey: '',
  company: {
    ...company,
    contacts: [
      {
        name: '',
        cpf: '',
        role: '',
        phone: '',
        mobile: '',
        email: '',
        department: '',
      },
    ],
  },
  productDetails,
  deliveryAddress,
  revenue,
};

const cleanCEP = dt => {
  if (dt) {
    const res = dt.replace('-', '');
    const final = res.replaceAll('.', '');
    return final.trim();
  }
  return '';
};

function EditContract({ idd, contract }) {
  const { t } = useTranslation();
  const router = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const [myContract, setMycontract] = useState();
  const [agent, setAgent] = useState();

  const formatValue = vl => {
    if (vl) {
      const i = vl.replaceAll('.', ',');
      return `R$ ${i}`;
    }
    return 'R$ 0,00';
  };

  useEffect(() => {
    if (id) {
      const cancel = new AbortController();
      api.contract
        .getContract(cancel, id)
        .then(res => {
          const listContacts = [];
          res.docs?.Contract?.ContractUser?.forEach(c => {
            listContacts.push({
              id: c.User?.id,
              name: c.User?.name,
              cpf: c.User?.cpf,
              role: c.User?.role_id,
              phone: c.User?.phone,
              mobile: c.User?.mobile,
              email: c.User?.email,
              department: c.User?.department,
            });
          });
          if (res.docs?.Contract?.ContractCommercialAgent[0]?.CommercialAgent) {
            setAgent({
              label:
                res.docs?.Contract?.ContractCommercialAgent[0]?.CommercialAgent
                  .company_name,
              value:
                res.docs?.Contract?.ContractCommercialAgent[0]?.CommercialAgent
                  .id,
            });
          }

          console.log(defaultContract);

          setMycontract({
            ...defaultContract,
            // id,
            // company: {
            //   ...company,
            //   cnpj: res.docs.LegalEntity.cnpj,
            //   companyName: res.docs.LegalEntity.company_name,
            //   tradingName: res.docs.LegalEntity.trading_name,
            //   email: res.docs.email,
            //   phone: res.docs.phone,
            //   mobile: res.docs.mobile,
            //   website: res.docs.LegalEntity.website,
            //   comments: res.docs.LegalEntity.comments,
            //   groupName: res.docs?.Contract?.group_name,
            //   contacts: listContacts,
            // },
            // deliveryAddress: {
            //   id: res.docs.Contract?.ContractDeliveryAddress?.[0]?.id,
            //   postalCode: res.docs.postal_code,
            //   street: res.docs.street_name,
            //   number: res.docs.number,
            //   complement: res.docs.complement,
            //   district: res.docs.district,
            //   city: res.docs.city,
            //   state: res.docs.state,
            //   receiver:
            //     res.docs.Contract?.ContractDeliveryAddress?.[0]?.recipient,
            //   deliveryAgent: res.docs.Contract?.delivery_agent,
            //   isCentralizedDelivery: res.docs.Contract?.is_centralized_delivery,
            //   deliveryService: res.docs.Contract?.delivery_service,
            //   otherAddress: {
            //     id: res.docs.Contract?.ContractDeliveryAddress?.[0]?.id,
            //     postalCode:
            //       res.docs.Contract?.ContractDeliveryAddress?.[0]?.postal_code,
            //     street:
            //       res.docs.Contract?.ContractDeliveryAddress?.[0]?.street_name,
            //     number: res.docs.Contract?.ContractDeliveryAddress?.[0]?.number,
            //     complement:
            //       res.docs.Contract?.ContractDeliveryAddress?.[0]?.complement,
            //     district:
            //       res.docs.Contract?.ContractDeliveryAddress?.[0]?.district,
            //     city: res.docs.Contract?.ContractDeliveryAddress?.[0]?.city,
            //     state: res.docs.Contract?.ContractDeliveryAddress?.[0]?.state,
            //     receiver:
            //       res.docs.Contract?.ContractDeliveryAddress?.[0]?.recipient,
            //     deliveryAgent: res.docs.Contract?.delivery_agent,
            //     isCentralizedDelivery:
            //       res.docs.Contract?.is_centralized_delivery,
            //     deliveryService: res.docs.Contract?.delivery_service,
            //   },
            // },
            productDetails: {
              commission_calculation: res.docs.Contract?.commission_calculation,
              operatorFees: {
                // For virtual_card and transfer
                isChanged: true,
                emitionTicket: 'R$ 3,50',
                pixPf: 'R$ 6,00',
                pixPj: 'R$ 6,00',
                lotteryDeposit: 'R$ 0,00',
                lotteryWithdrawal: 'R$ 0,00',
                PDV: '0,00',
                aditionalCard: 'R$ 0,00',
                expiredCard: 'R$ 0,00',
                codeQR: 'R$ 8,00',
                TED: 'R$ 9,00',
                SMS: 'R$ 0,00',
                // only for cards
                monthlyFee: formatValue(res.docs.Contract?.monthly_fee), // 'R$ 0,00',
                unlockFee: formatValue(res.docs.Contract?.unlock_fee), // 'R$ 0,00',
                reissueFee: formatValue(res.docs.Contract?.reissue_fee), // 'R$ 0,00',
                chargebackFee: formatValue(res.docs.Contract?.chargeback_fee), // 'R$ 0,00',
                atmWithdrawFee: formatValue(
                  res.docs.Contract?.atm_withdraw_fee,
                ), // 'R$ 0,00',
                markupRate: res.docs.Contract?.markup_rate, // '0,00',
                rechargePortalFee: 'R$ 0,00',
                rechargeInvoiceFee: 'R$ 0,00',
                p2pTransferFee: formatValue(
                  res.docs.Contract?.p2p_transfer_fee,
                ), // 'R$ 0,00',
                // only for transfers
                transferFee: formatValue(res.docs.Contract?.transfer_fee), // 'R$ 0,00',
                rejectedTransactionFee: formatValue(
                  res.docs.Contract?.rejected_transaction_fee,
                ), // 'R$ 0,00',
                // only for checks
                checkFee: formatValue(res.docs.Contract?.check_fee), // 'R$ 0,00',
                // only for vouchers
                voucherFee: formatValue(res.docs.Contract?.voucher_fee), // 'R$ 0,00',
              },
              cardFees: {
                physicalCard: formatValue(res.docs.Contract?.issue_fee), // 'R$ 8,50',
                sendCard: 'R$ 0,00',
                cardWithdrawal: 'R$ 0,00',
              },
              campaign: {
                name: '',
                rechargeFrequency:
                  res.docs.Contract?.readjustment_period?.toString(),
                annualEstimatedAward: formatValue(
                  res.docs.Contract?.annual_estimated_award,
                ), // 'R$ 0,00',
                totalCommissionRate: formatValue(
                  res.docs.Contract?.total_commission_rate,
                ),
                isAdministrationRate: res.docs.Contract?.is_administration_rate,
                // only when is_administration_rate is true
                administrationRate: res.docs.Contract?.administration_rate,
                // '5.00',
                // only when is_administration_rate is false
                administrationFee: formatValue(
                  res.docs.Contract?.administration_fee,
                ), // 'R$ 0,00',
                // only when product category is a card
                issueFee: formatValue(res.docs.Contract?.issue_fee), // 'R$ 0,00',
              },
              product:
                res.docs.Contract?.product_type === 'Card'
                  ? 'prepaid_card'
                  : 'transfer',
              productBrand: res.docs.Contract?.brand, // 'mastercard',
              commercialAgent: {
                id: 'CsFaIoewgVLlz5mZOgSI',
                company: {
                  admin: {
                    companyArea: 'venda',
                    companyRole: 'vendedor',
                    cpf: '11111111111',
                    fullName: 'John Doe',
                    email: 'churi410@gmail.com',
                    mobile: '5804269900709',
                    phone: '5804269900709',
                  },
                  cnpj: '11111111111111',
                  comments: '',
                  email: 'churi410@gmail.com',
                  legalName: ' Element Marketing e Participações',
                  tradingName: ' Element Marketing e Participações',
                  mobile: '5804269900709',
                  phone: '5804269900709',
                  website: 'www.churi.com.br',
                },
                salesCommissionId: 'ga3ze2XuOSX7FsD9T80e',
              },
              // only for cards
              companyOperatingFees: {
                isChanged: true,
                KYC: 'R$ 0,00',
                balanceTransferFee: formatValue(
                  res.docs.Contract?.balance_transfer_fee,
                ), // 'R$ 0,00',
                minimumLoadAmount: formatValue(
                  res.docs.Contract?.minimum_load_amount,
                ), // 'R$ 0,00',
                belowMinimumLoadFee: formatValue(
                  res.docs.Contract?.below_minimum_load_fee,
                ), // 'R$ 0,00',
                emergencyLoadFee: formatValue(
                  res.docs.Contract?.emergency_load_fee,
                ), // 'R$ 0,00',
                specialHandlingFee: formatValue(
                  res.docs.Contract?.special_handling_fee,
                ), // 'R$ 0,00',
                chargebackRate: res.docs.Contract?.chargeback_rate, // '0,00',
                rejectedTransactionFee: formatValue(
                  res.docs.Contract?.rejected_transaction_fee,
                ), // 'R$ 0,00',
              },
              salesChannel: res.docs.Contract?.sales_channel, // 'direct',
              classAccount: 'basic',
              isGiftCard: !res.docs.Contract?.allow_withdraw,
              typeCard: !res.docs.Contract?.typeCard,
              // isVirtualCard: !res.docs.Contract?.is_virtual_card,
              kindAccount: 'virtual_card',
              isInternationalCard: res.docs.Contract?.is_international_card, // true,
              cardQuantity: 1,
              isChipCard: res.docs.Contract?.is_chip_card, // true,
              issuer: res.docs.Contract?.issuer, // 'acesso',
              // revenue: {
              //   calculation: res.docs.Contract.is_normal_calculation
              //     ? 'normal'
              //     : 'inverted',
              //   currency: 'brl',
              //   paymentMethod: res.docs.Contract.is_transfer_payment
              //     ? 'transfer'
              //     : 'ticket',
              //   isInvoiceTopup: true,
              //   issuingCompanyId: 1,
              //   bankAccountId: 1,
              //   serviceCodeId: 8,
              //   signatureDate: new Date(),
              //   expirationDate: new Date(),
              //   isSigned: false,
              // },
            },
            revenue: {
              calculation: res.docs.Contract.is_normal_calculation
                ? 'normal'
                : 'inverted',
              currency: 'brl',
              paymentMethod: res.docs.Contract.is_transfer_payment
                ? 'transfer'
                : 'ticket',
              isInvoiceTopup: res.docs.Contract.is_invoice_topup,
              issuingCompanyId: 1,
              bankAccountId: Number(res.docs.Contract?.bank_account_id),
              serviceCodeId: 8,
              signatureDate: new Date(),
              expirationDate: new Date(),
              isSigned: false,
            },
          });
        })
        .catch(err => console.log(err));
    }
  }, [id]);

  async function editCompany(data, address, contractId, cnpj) {
    // eslint-disable-next-line no-param-reassign
    address.postalCode = cleanCEP(address.postalCode);
    return api.contract.updateCompany(data, address, contractId, cnpj);
  }

  async function editContract(
    operatorFees,
    companyOperatingFees,
    cardFees,
    campaign,
    mproductDetails,
    mDelivery,
    mrevenue,
    contractId,
    groupName,
  ) {
    return api.contract.updateContract(
      operatorFees,
      companyOperatingFees,
      cardFees,
      campaign,
      mproductDetails,
      mDelivery,
      mrevenue,
      contractId,
      groupName,
    );
  }

  async function editDeliveryAddress(data, contractId) {
    return api.contract.updateDeliveryAddress(data, contractId);
  }

  async function editUsers(users, contractId) {
    return api.contract.updateUser(users, contractId);
  }

  const cleanValue = vl => {
    const res = vl?.replace('R$', ' ');
    const final = res?.replaceAll(',', '.');
    return final?.trim();
  };

  const onSuccessContract = mycontract =>
    api.contract.create(
      true,
      mycontract.company.contacts,
      [
        {
          cnpj: mycontract.company.cnpj,
          company_name: mycontract.company.companyName,
          trading_name: mycontract.company.tradingName,
          website: mycontract.company.website,
          comments: mycontract.company.comments,
          phone: mycontract.company.phone,
          mobile: mycontract.company.mobile,
          email: mycontract.company.email,
          postal_code: cleanCEP(mycontract.deliveryAddress.postalCode),
          street_name: mycontract.deliveryAddress.street,
          number: mycontract.deliveryAddress.number,
          complement: mycontract.deliveryAddress.complement,
          district: mycontract.deliveryAddress.district,
          city: mycontract.deliveryAddress.city,
          state: mycontract.deliveryAddress.state,
          is_default: true,
          is_active: true,
          allow_invoice: true,
        },
      ],
      {
        postal_code: cleanCEP(
          !mycontract.deliveryAddress.isCentralizedDelivery
            ? mycontract.deliveryAddress?.otherAddress?.postalCode
            : mycontract.deliveryAddress?.otherAddress?.postalCode,
        ),
        street_name: !mycontract.deliveryAddress.isCentralizedDelivery
          ? mycontract.deliveryAddress?.otherAddress?.street
          : mycontract.deliveryAddress?.street,
        number: !mycontract.deliveryAddress.isCentralizedDelivery
          ? mycontract.deliveryAddress?.otherAddress?.number
          : mycontract.deliveryAddress?.number,
        complement: !mycontract.deliveryAddress.isCentralizedDelivery
          ? mycontract.deliveryAddress?.otherAddress?.complement
          : mycontract.deliveryAddress?.complement,
        district: !mycontract.deliveryAddress.isCentralizedDelivery
          ? mycontract.deliveryAddress?.otherAddress?.district
          : mycontract.deliveryAddress?.district,
        city: !mycontract.deliveryAddress.isCentralizedDelivery
          ? mycontract.deliveryAddress?.otherAddress?.city
          : mycontract.deliveryAddress?.city,
        state: !mycontract.deliveryAddress.isCentralizedDelivery
          ? mycontract.deliveryAddress?.otherAddress?.state
          : mycontract.deliveryAddress?.state,
        recipient: !mycontract.deliveryAddress.isCentralizedDelivery
          ? mycontract.deliveryAddress?.receiver
          : mycontract.deliveryAddress?.receiver,
      },
      [],
      mycontract.productDetails.campaign.isAdministrationRate,
      mycontract.productDetails.product === 'prepaid_card'
        ? 'Card'
        : 'Transfer',
      mycontract.deliveryAddress.isCentralizedDelivery,
      true,
      mycontract.revenue.bankAccountId,
      mycontract.revenue.serviceCodeId,
      mycontract.company?.campaign?.label,
      mycontract.company?.groupName, // -> Nome GRUPO
      cleanValue(mycontract.productDetails.campaign.annualEstimatedAward),
      mycontract.revenue.currency,
      mycontract.revenue.signatureDate,
      mycontract.revenue.expirationDate,
      cleanValue(mycontract.productDetails.campaign.totalCommissionRate),
      mycontract.productDetails.campaign.rechargeFrequency, // periodo de reajuste numero
      mycontract.productDetails.salesChannel,
      mycontract.revenue.calculation === 'normal',
      mycontract.revenue.paymentMethod === 'transfer',
      mycontract.revenue.isInvoiceTopup,
      // Condições comerciais
      cleanValue(mycontract.productDetails.campaign.administrationRate),
      cleanValue(mycontract.productDetails.campaign.administrationFee),
      0.0, // exchangeFee -- precisa implementar ,
      true, // applyAdministrationRate - precisa implementar
      // Obrigatórios se product_type = "card"
      mycontract.productDetails.issuer,
      mycontract.productDetails.productBrand,
      mycontract.productDetails.isChipCard,
      mycontract.productDetails.isInternationalCard,
      false, // isDisposable - preccisa implementar
      !mycontract.productDetails.isGiftCard, // allowWithdraw
      cleanValue(
        mycontract.productDetails.companyOperatingFees.balanceTransferFee,
      ),
      cleanValue(mycontract.productDetails.operatorFees.markupRate),
      // Pagos pelo Cliente Element
      cleanValue(mycontract.productDetails.campaign.issueFee),
      cleanValue(
        mycontract.productDetails.companyOperatingFees.minimumLoadAmount,
      ),
      cleanValue(
        mycontract.productDetails.companyOperatingFees.belowMinimumLoadFee,
      ),
      cleanValue(
        mycontract.productDetails.companyOperatingFees.emergencyLoadFee,
      ),
      cleanValue(
        mycontract.productDetails.companyOperatingFees.specialHandlingFee,
      ),
      // Pagos pelo Premiado
      cleanValue(mycontract.productDetails.operatorFees.monthlyFee),
      cleanValue(mycontract.productDetails.operatorFees.unlockFee),
      cleanValue(mycontract.productDetails.operatorFees.reissueFee),
      cleanValue(mycontract.productDetails.operatorFees.chargebackFee),
      cleanValue(mycontract.productDetails.companyOperatingFees.chargebackRate),
      cleanValue(mycontract.productDetails.operatorFees.atmWithdrawFee),
      9.0, // bankWithdrawFee - precisa implementar
      // Obrigatórios se product_type = "transfer"
      true, // isTed - precisa implementar
      cleanValue(mycontract.productDetails.operatorFees.transferFee),
      mycontract.productDetails.product === 'prepaid_card'
        ? cleanValue(
            mycontract.productDetails.operatorFees.rejectedTransactionFee,
          )
        : cleanValue(
            mycontract.productDetails.companyOperatingFees
              .rejectedTransactionFee,
          ),
      // Obrigatórios se product_type = "check"
      cleanValue(mycontract.productDetails.operatorFees.checkFee),
      // Obrigatórios se product_type = "voucher"
      cleanValue(mycontract.productDetails.operatorFees.voucherFee),
      // Entrega
      mycontract.deliveryAddress.deliveryAgent,
      mycontract.deliveryAddress.deliveryService,
      '', // comments - precisa implementar
      '', // signeeName - verificar se precisa implementar
      '', // signeeCpf - verificar se precisa implementar ,
      '', // signeePost - verificar se precisa implementar ,
      '', // area - ROLIM NÃO SABE PRA QUE SERVE,
      mycontract.productDetails.commission_calculation,
    );
  // async function onSuccessContract(mycontract) {
  //   // return axios.put(`/api/contracts/${idd}`, mycontract);
  //   await editContract(
  //     mycontract.productDetails.operatorFees,
  //     mycontract.productDetails.companyOperatingFees,
  //     mycontract.productDetails.cardFees,
  //     mycontract.productDetails.campaign,
  //     mycontract.productDetails,
  //     mycontract.deliveryAddress,
  //     mycontract.revenue,
  //     id,
  //     mycontract?.company?.groupName,
  //   );
  //   await editDeliveryAddress(mycontract.deliveryAddress, id);
  //   await editUsers(mycontract.company.contacts, id);
  //   return editCompany(
  //     mycontract.company,
  //     mycontract.deliveryAddress,
  //     id,
  //     mycontract.company.cnpj,
  //   );
  //   // return editDeliveryAddress(mycontract.deliveryAddress, id);
  // }

  // const onSuccessContract = mycontract => {
  //   // return axios.put(`/api/contracts/${idd}`, mycontract);
  //   await editCompany()
  // };

  const callback = () => {
    // router.push('/allContracts');
    router.push('/sales/contracts');
  };

  if (!myContract) {
    return <SpinnerPage open />;
  }

  return (
    <>
      <Grid
        className={classes.header}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ marginTop: 50 }}
      >
        <p
          className={styles.textMenu}
          onKeyDown={() => router.push(routing.allContracts)}
          onClick={() => router.push(routing.allContracts)}
        >
          Menu Inicial
        </p>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h4 className={styles.textTitle}>Duplicar contrato</h4>
        </div>
        {/* <Typography variant="h4" component="h2" gutterBottom>
          Editar contrato
        </Typography> */}
      </Grid>
      {myContract && (
        <ContractForm
          contract={myContract}
          agent={agent}
          // eslint-disable-next-line react/jsx-no-bind
          onSuccess={onSuccessContract}
          code="API_RES_SUCCESS"
          // titleModal={t('editContract.successMsg')}
          titleModal="Contrato duplicado com sucesso"
          actionModal={callback}
          control
          // edit
          duplicate
        />
      )}
    </>
  );
}

export const getServerSideProps = async context => {
  const id = context.query.contractId;
  try {
    // const {
    //   data: { company, deliveryAddress, productDetails, revenue },
    // } = await privateFetch(context).get(`/contracts/${id}`);
    const cancel = new AbortController();
    // const {
    //   data: { company, deliveryAddress, productDetails, revenue },
    // } = await api.contract.getContract(cancel, id);

    return {
      props: {
        id,
        contract: {
          company,
          deliveryAddress,
          productDetails,
          revenue,
        },
      },
    };
  } catch (err) {
    // eslint-disable-next-line no-unused-vars
    const error = err;

    return {
      notFound: true,
    };
  }
};

export default EditContract;
