/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// import AppBar from '@mui/material/AppBar';
// import { styled, useTheme } from '@mui/material/styles';
import styled from 'styled-components';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Badge,
  MenuItem,
  Menu,
  Avatar,
  Dialog,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import storage from '../service/axios/storage';
import routing from '../service/routes/routing';
import PermissionComponent from '../components/permission/Permission';
import NewPermission from '../components/permissionComponent/PermissionComponent';
import api from '../service/axios/api';
import UserRoles from '../service/acl/UserRoles';
import EmailTextNotification from '../pages/notifications/saved/EmailTextNotification';

const drawerWidth = 240;
const MyAppBar = styled(AppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      flexGrow: 1,
      // minWidth: 200,
      '&:hover': {
        // backgroundColor: theme.palette.primary.light,
      },
      // maxWidth: 318,
    },
    logo: {
      // flexGrow: 1,
      width: 149,
      // height: 70,
    },
    avatar: {
      cursor: 'pointer',
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    name: {
      marginRight: 5,
    },
    textNotification: {
      fontFamily: 'Open Sans',
      lineHeight: 'auto',
      color: '#707070',
    },
  }),
);

export default function TopBar({ title, onToggleSideMenu, sideMenuOpen }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotifications = () => {
    // history.push(routing.notifications);
    setOpenModal(true);
    setAnchorEl(null);
  };

  const handleUsers = () => {
    history.push(routing.users);
    setAnchorEl(null);
  };

  const handleProfile = () => {
    history.push(routing.profile);
    setAnchorEl(null);
  };

  const handleAdm = () => {
    history.push(routing.administration);
    setAnchorEl(null);
  };

  const logout = () => {
    storage.auth.token = null;
    history.push(routing.login);
  };
  const classes = useStyles();

  return (
    <>
      <AppBar position="fixed" open={sideMenuOpen}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={onToggleSideMenu}
            edge="start"
            sx={{ mr: 2, ...(sideMenuOpen && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <img
            src="../img/controlEcosystem.png"
            alt="logo"
            className={classes.logo}
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(routing.dashboard)}
          />
          <Typography style={{ marginLeft: 40 }} variant="h6" component="div">
            {title}
          </Typography>
          <div style={{ flexGrow: 1 }} />
          <div>
            <IconButton
              size="medium"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <Typography
                variant="h6"
                component="div"
                style={{ marginRight: 50 }}
                onClick={handleMenu}
              >
                {api.currentUser.user.name}
              </Typography>
              <Avatar
                alt="Remy Sharp"
                src="./img/whats.png"
                style={{ cursor: 'pointer', width: 30, height: 30 }}
                onClick={() =>
                  window.open(
                    'https://api.whatsapp.com/send/?phone=551131810646&text&type=phone_number&app_absent=0',
                    '_blank',
                  )
                }
              />
              {/* <Badge
              // overlap="rectangular"
              badgeContent={1}
              color="error"
              style={{ marginLeft: 25, marginRight: 25 }}
            /> */}
            </IconButton>
            <ExitToAppIcon
              style={{ cursor: 'pointer' }}
              onClick={() => logout()}
            />
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              //  anchorOrigin={{
              //    vertical: 'top',
              //    horizontal: 'right',
              //  }}
              keepMounted
              //  transformOrigin={{
              //    vertical: 'top',
              //    horizontal: 'right',
              //  }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {(api.currentUser.id === '214' ||
                api.currentUser.id === '215' ||
                api.currentUser.id === '8' ||
                api.currentUser.id === '9' ||
                api.currentUser.id === '278' ||
                api.currentUser.id === '18' ||
                api.currentUser.id === '240' ||
                api.currentUser.id === '287' ||
                api.currentUser.id === '255' ||
                api.currentUser.id === '1203') && (
                  <NewPermission role={UserRoles.DASHBOARD_ADMINISTRATION}>
                    <MenuItem
                      className={classes.textNotification}
                      onClick={handleAdm}
                    >
                      {t('administration')}
                    </MenuItem>
                  </NewPermission>
                )}
              <MenuItem
                className={classes.textNotification}
                onClick={() =>
                  window.open(
                    'https://elementcontrol.azurewebsites.net',
                    '_blank',
                  )
                }
              >
                Desenvolvedores
              </MenuItem>
              <PermissionComponent role="1">
                <MenuItem
                  className={classes.textNotification}
                  onClick={handleClose}
                >
                  {t('conf')}
                </MenuItem>
              </PermissionComponent>
              <NewPermission role={UserRoles.ADM_MANAGER_USERS}>
                <MenuItem
                  className={classes.textNotification}
                  onClick={handleUsers}
                >
                  {t('confUser')}
                </MenuItem>
              </NewPermission>

              <MenuItem
                className={classes.textNotification}
                onClick={handleProfile}
              >
                Meu perfil
              </MenuItem>

              {(api.currentUser.id === '214' ||
                api.currentUser.id === '215' ||
                api.currentUser.id === '8' ||
                api.currentUser.id === '9') && (
                  <MenuItem
                    className={classes.textNotification}
                    onClick={handleNotifications}
                  >
                    {t('notification')}
                  </MenuItem>
                )}

              {/* </PermissionComponent> */}
              {/* <PermissionComponent role="1"> */}
              <MenuItem
                className={classes.textNotification}
                onClick={() => history.push(routing.tutorial)}
              >
                {t('tutorial')}
              </MenuItem>

              <MenuItem
                className={classes.textNotification}
                onClick={() => logout()}
              >
                {t('OrderDetails.exit')}
              </MenuItem>
              {/* </PermissionComponent> */}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        maxWidth="lg"
        fullWidth
      >
        <EmailTextNotification
          destination={{
            title: 'title',
            id: 1,
          }}
          setOpenModal={setOpenModal}
        />
      </Dialog>
    </>
    //     <AppBar position="static" className={classes.root}>
    //       <Toolbar>
    //         <IconButton
    //           size="large"
    //           edge="start"
    //           color="inherit"
    //           aria-label="menu"
    //           onClick={onToggleSideMenu}
    //           sx={{ mr: 2, ...(sideMenuOpen && { display: 'none' }) }}
    //           // sx={{ mr: 2 }}
    //         >
    //           <MenuIcon />
    //         </IconButton>
    //         <img src="./img/controlEcosystem.png" alt="logo" className={classes.logo} />
    //         <Typography style={{ marginLeft: 40 }} variant="h6" component="div">
    //             {title}
    //         </Typography>
    //           <div style={{ flexGrow: 1 }} />
    //           {/* <Typography variant="h6" component="div">
    //             Nome usuário
    //           </Typography>
    //           <Badge overlap="rectangular" badgeContent={4}
    //           color="error" style={{ marginLeft: 25, marginRight: 25 }} /> */}
    //           <div>
    //             <IconButton
    //               size="large"
    //               aria-label="account of current user"
    //               aria-controls="menu-appbar"
    //               aria-haspopup="true"
    //               onClick={handleMenu}
    //               color="inherit"
    //             >
    //               <Typography variant="h6" component="div">
    //             Nome usuário
    //               </Typography>
    //           <Badge overlap="rectangular"
    // badgeContent={4} color="error" style={{ marginLeft: 25, marginRight: 25 }} />
    //             </IconButton>
    //             <ExitToAppSharpIcon style={{ cursor: 'pointer' }} onClick={() => logout()} />
    //              <Menu
    //                id="menu-appbar"
    //                anchorEl={anchorEl}
    //               //  anchorOrigin={{
    //               //    vertical: 'top',
    //               //    horizontal: 'right',
    //               //  }}
    //                keepMounted
    //               //  transformOrigin={{
    //               //    vertical: 'top',
    //               //    horizontal: 'right',
    //               //  }}
    //                open={Boolean(anchorEl)}
    //                onClose={handleClose}
    //              >
    //                <MenuItem
    //                  className={classes.textNotification}
    //                  onClick={handleClose}
    //                >
    // Configurações de conta

    //                </MenuItem>
    //                <MenuItem
    //                  className={classes.textNotification}
    //                  onClick={handleClose}
    //                >
    // Gerenciar Usuários

    //                </MenuItem>

    //                <MenuItem
    //                  className={classes.textNotification}
    //                  onClick={handleClose}
    //                  style={{ color: '#41B490' }}
    //                >
    //                 Notificações
    //                 <Badge overlap="rectangular"
    //  badgeContent={4} color="error" style={{ marginLeft: 70 }} />
    //                </MenuItem>

    //               <MenuItem
    //                 className={classes.textNotification}
    //                 onClick={handleClose}
    //               >
    // Tutoriais

    //               </MenuItem>
    //              </Menu>
    //           </div>
    //         {/* )} */}
    //       </Toolbar>
    //     </AppBar>
  );
}
